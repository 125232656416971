// utils
import { getQueryStringParameter } from "./url-search-util.js";
import { base64Decoder } from "./encoder.js";
import { Utilities } from "../utils.js";

export function getLayoutsColumnConfigPreset(store, storeId) {
  let columnConfig = undefined;
  const columnConfigLayout = store[storeId];
  columnConfig = Utilities.deepCopy(columnConfigLayout);

  return columnConfig;
}

export function getColumnConfigDiffFromUrl() {
  const { hash } = window.location;
  const hashSubstring = hash.substring(1);

  const columnConfigDiffUrlEncoded = getQueryStringParameter(
    hashSubstring,
    "columnConfig"
  );

  if (
    columnConfigDiffUrlEncoded === null ||
    columnConfigDiffUrlEncoded === undefined
  )
    return undefined;

  const columnConfigDiffUrlDecoded = decodeURIComponent(
    columnConfigDiffUrlEncoded
  );
  const columnConfigDiff = base64Decoder(columnConfigDiffUrlDecoded);

  return columnConfigDiff;
}

export function getColumnConfigPresetColumns(columnConfigPreset) {
  const columns = [];
  for (let [key, value] of Object.entries(columnConfigPreset)) {
    if (key.startsWith("ui")) {
      continue;
    }

    const columnObjCopy = Utilities.deepCopy(value);
    columnObjCopy.configName = key;
    columns.push(columnObjCopy);
  }

  return columns;
}

export function getColumnConfigPresetsForLayout(
  columnConfigPresetsStore,
  layout
) {
  let columnConfigPresets = [];

  for (let [columnConfigKey, columnConfigPreset] of Object.entries(
    columnConfigPresetsStore
  )) {
    const isColumnConfigPresetSupported = columnConfigPreset.uiColumnConfigPreset.supportedLayouts.some(
      supportedLayout => supportedLayout === layout
    );

    if (isColumnConfigPresetSupported) {
      columnConfigPresets.push({
        storeConfigName:
          columnConfigPreset.uiColumnConfigPreset.columnConfigId,
        columnConfigName:
          columnConfigPreset.uiColumnConfigPreset.columnConfigName
      });
    }
  }

  return columnConfigPresets;
}

export function getColumnConfigPresetAndDiff(store, presetId) {
  const columnConfigId = presetId;
  const columnConfigPreset = getLayoutsColumnConfigPreset(
    store,
    columnConfigId
  );

  const columnConfigDiff = getColumnConfigDiffFromUrl();

  return {
    configPreset: columnConfigPreset,
    configDiff: columnConfigDiff
  };
}

export function updateColumnConfig(
  columnConfigStore,
  columnConfigId,
  columnConfig
) {
  columnConfigStore.columnConfigId = columnConfigId;
  for (let [key, value] of Object.entries(columnConfig)) {
    if (key.startsWith("ui")) continue;
    columnConfigStore[key] = value;
  }
  return columnConfigStore;
}

export function hasColumnConfigDiff(diff) {
  return !Utilities.isObjectEmpty(diff);
}
