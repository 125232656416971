<script>
  import {fade} from "svelte/transition";
  import {onMount} from "svelte";

  // components
  import DisplaySaveTemplateConfigurationPopUp from "./DisplaySaveTemplateConfigurationPopUp.svelte";
  import ModalDialog from "./widgets/ModalDialog.svelte";
  import NavbarConfigHeader from "./NavbarConfigHeader.svelte";
  import NavbarConfigSave from "./NavbarConfigSave.svelte";
  import NavbarSegmentedConfigSave from "./NavbarSegmentedConfigSave.svelte";
  import NavbarMyConfigurationsList from "./NavbarMyConfigurationsList.svelte";
  import NavbarMySegmentedScreensList from "./NavbarMySegmentedScreensList.svelte";
  import NavbarLogin from "./NavbarLogin.svelte";

  // stores
  import {
    loadUserFromLocalStorage,
    userStore,
    authTokenStore
  } from "./store-user.js";

  // input properties
  export let title = "Wemlin Departure Monitor Configurator";
  export let rootLink = ".";
  export let loadedConfig = undefined;
  export let showLoginFormOnMount = false;
  export let loadedSegmentedConfig = undefined;

  // internal properties
  $: userLoggedIn = $authTokenStore != undefined && $userStore != undefined;

  // show modals
  let showSuccessNotification = undefined;
  let showFailNotification = undefined;
  let showWarningModal = undefined;

  onMount(async () => {
    loadUserFromLocalStorage();
  });

  function showNotification(event) {
    if (event.detail.success) {
      showSuccessNotification = event.detail.text;
      showFailNotification = undefined;
      setTimeout(() => (showSuccessNotification = undefined), 3000);
    } else {
      showSuccessNotification = undefined;
      showFailNotification = event.detail.text;
      setTimeout(() => (showFailNotification = undefined), 3000);
    }
  }

  function showModal(event) {
    showWarningModal = event.detail.text;
  }
</script>

<nav
  id="mainNavbar"
  class="navbar"
  role="navigation"
  aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item " href={rootLink}>
      <img src="favicon.png" alt="Wemlin Logo" />
      &nbsp;
      <h1>{title}</h1>
    </a>

    {#if loadedConfig}
      <NavbarConfigHeader
        {loadedConfig}
        on:showNotification={showNotification} />
    {:else if loadedSegmentedConfig}
      <NavbarConfigHeader
        loadedConfig={loadedSegmentedConfig}
        on:showNotification={showNotification} />
    {/if}

    <a
      role="button"
      class="navbar-burger burger"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarMenu">
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </a>
  </div>

  <div id="navbarMenu" class="navbar-menu">
    <div class="navbar-end">

      {#if loadedConfig}
        <NavbarConfigSave
          {userLoggedIn}
          {loadedConfig}
          on:showModal={showModal}
          on:showNotification={showNotification} />
      {:else if loadedSegmentedConfig}
        <NavbarSegmentedConfigSave
          {userLoggedIn}
          loadedConfig={loadedSegmentedConfig}
          on:showModal={showModal}
          on:showNotification={showNotification} />
      {/if}

      {#if userLoggedIn}
        {#if loadedConfig}
          <NavbarMyConfigurationsList {userLoggedIn} {loadedConfig} />
        {:else if loadedSegmentedConfig}
          <NavbarMySegmentedScreensList loadedConfig={loadedSegmentedConfig} />
        {/if}
      {/if}
      <NavbarLogin {showLoginFormOnMount} />

    </div>
  </div>
</nav>

{#if showSuccessNotification}
  <div
    class="notification is-success modal-notification is-active"
    transition:fade>
    {showSuccessNotification}
  </div>
{/if}

{#if showFailNotification}
  <div
    class="notification is-danger modal-notification is-active"
    transition:fade>
    {showFailNotification}
  </div>
{/if}

<ModalDialog bind:active={showWarningModal} width="600">
  <article class="no-station-selected media">
    <div class="warning-icon media-left">
      <span class="icon has-text-warning is-large">
        <i class="fas fa-exclamation-triangle fa-3x" />
      </span>
    </div>
    <div class="no-station-selected-content media-content">
      <div class="content">
        <p>{showWarningModal}</p>
      </div>
    </div>
  </article>
</ModalDialog>
