<script>
  export let id = "";
  export let value;
  export let disabled = false;

  export let title;

  export let step = 1;
  export let floatStep = "";
  $: isFloat = floatStep !== "";

  export let defaultValue;

  export let min = 0;
  export let max = Number.MAX_SAFE_INTEGER;
  $: if (value > max) {
    value = max;
  }

  $: if (value < min) {
    value = min;
  }

  $: if (!value && defaultValue) {
    value = defaultValue;
  }

  function round2(number) {
    const result = Math.round(number * 100) / 100;
    return result;
  }
</script>

<style>
  .floatSection {
    display: block;
    float: left;
    width: 70%;
    height: 100%;
  }
  .floatButton {
    display: block;
    float: left;
    width: 28px;
    height: 18px;
    font-size: 10px;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  .up {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .down {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .noRadiusRight {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
</style>

<div>

  <div class="floatSection" style="margin-right: -1px;">
    <input {id} {title} class="input noRadiusRight" type="text" bind:value {disabled} />
  </div>

  <div class="floatSection" style="width: 30px;">
    <button
      class="button floatButton up"
      {disabled}
      {title}
      on:click={() => (value = isFloat ? round2(parseFloat(value) + parseFloat(floatStep)) : parseInt(value) + parseInt(step))}>
      <span class="icon is-small">
        <i class="fas fa-chevron-up" aria-hidden="true" />
      </span>
    </button>
    <button
      class="button floatButton down"
      {disabled}
      {title}
      on:click={() => (value = isFloat ? round2(parseFloat(value) - parseFloat(floatStep)) : parseInt(value) - parseInt(step))}>
      <span class="icon is-small">
        <i class="fas fa-chevron-down" aria-hidden="true" />
      </span>
    </button>
  </div>

</div>
