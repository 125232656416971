<script>
  import { onMount, createEventDispatcher } from "svelte";

  // store
  import { dm1 } from "../stores.js";
  import {
    columnConfigStore,
    currentColumnConfigStore
  } from "../store-column-config.js";

  // components
  import ColumnsVisibilityAndOrder from "./ColumnsVisibilityAndOrder.svelte";
  import ColumnsWidth from "./ColumnsWidth.svelte";
  import ColumnsTextAndSpacing from "./ColumnsTextAndSpacing.svelte";
  import ModalDialog from "../widgets/ModalDialog.svelte";
  import ColumnConfigComboBox from "./ColumnConfigComboBox.svelte";

  // utils
  import { Utilities } from "../utils.js";
  import { base64Encoder, base64Decoder } from "../util/encoder.js";
  import { getQueryStringParameter } from "../util/url-search-util.js";
  import {
    getLayoutsColumnConfigPreset,
    getColumnConfigDiffFromUrl,
    getColumnConfigPresetAndDiff,
    hasColumnConfigDiff
  } from "../util/column-config-utils.js";

  // enum
  const LanguageLabelEnum = Object.freeze({
    EN: "EN",
    DE: "DE",
    FR: "FR",
    IT: "IT"
  });

  // input properties
  export let columnsDialogOpen = false;
  export let columnConfig = undefined;
  export let columnConfigDiff = undefined;
  export let selectedColumnConfigPreset = undefined;

  // dispatcher
  const dispatch = createEventDispatcher();

  // model
  let isDefaultColumnConfigChanged = false;

  let selectedTabIndex = 0;

  let columns = [];
  let visibleColumns = undefined;

  let gapBetweenThumbsInPercent = [];

  // delete if unnecessary
  let hiddenColumns = undefined;

  // reactives
  $: selectedColumnConfigPreset, findAdvancedColumnConfig();
  $: columnConfig, getColumns();
  $: columns, divideByVisibility();
  $: visibleColumns, calculateGapBetweenThumbs(), setColumnConfigPreset();

  function getColumns() {
    for (const [key, value] of Object.entries(columnConfig)) {
      if (key.startsWith("ui")) continue;

      columns[value.pos - 1] = {
        configName: key,
        pos: value.pos,
        label: parseColumnLabel(value.label),
        name: value.name,
        show: value.show,
        width: value.width,
        align: value.align,
        pL: value.pL,
        pR: value.pR,
        disabled: value.disabled
      };
    }
  }

  function divideByVisibility() {
    visibleColumns = [];
    hiddenColumns = [];
    columns.forEach(column =>
      column.show ? visibleColumns.push(column) : hiddenColumns.push(column)
    );

    visibleColumns = visibleColumns;
    hiddenColumns = hiddenColumns;
  }

  function findAdvancedColumnConfig() {
    const { configPreset, configDiff } = getColumnConfigPresetAndDiff(
      $columnConfigStore,
      $currentColumnConfigStore.columnConfigId
    );

    console.log(
      "CONFIG PRESET: ",
      configPreset.uiColumnConfigPreset.columnConfigName
    );

    // columnConfigDiff = configDiff;
    columnConfig =
      columnConfigDiff === undefined
        ? configPreset
        : Utilities.mergeDeepRight(configPreset, columnConfigDiff);
  }

  function parseColumnLabel(inputLabel) {
    let languageLabel = undefined;
    try {
      for (let [key, value] of Object.entries(LanguageLabelEnum)) {
        if (!inputLabel.hasOwnProperty(key.toLowerCase())) {
          console.warn(
            "Label is missing all languages translation: " + key,
            inputLabel
          );
        }
      }
      languageLabel = inputLabel;
    } catch (err) {
      console.error(err);
      languageLabel = {
        en: "",
        de: "",
        fr: "",
        it: ""
      };
    }

    return languageLabel;
  }

  function allowCustomColumnSettingsTab(selectedTab) {
    return visibleColumns.length === 0
      ? null
      : (selectedTabIndex = selectedTab);
  }

  function setColumnConfigPreset() {
    columnConfigDiff = Utilities.deepDiff(
      $columnConfigStore[$currentColumnConfigStore.columnConfigId],
      columnConfig
    );

    if (hasColumnConfigDiff(columnConfigDiff)) {
      selectedColumnConfigPreset = "CUSTOM_LAYOUT";
      isDefaultColumnConfigChanged = true;
    } else {
      selectedColumnConfigPreset = $currentColumnConfigStore.columnConfigId;
      isDefaultColumnConfigChanged = false;
    }
  }
  // event handlers
  function handleChangeInSelectedColumnConfigPreset(event) {
    const { payload } = event.detail;

    if (payload !== "CUSTOM_LAYOUT") {
      columnConfigDiff = {};
      $currentColumnConfigStore.columnConfigId = payload;
      isDefaultColumnConfigChanged = false;
    }
    selectedColumnConfigPreset = payload;
  }

  function updateScreenColumnConfig() {
    const defaultColumnConfig =
      $columnConfigStore[$currentColumnConfigStore.columnConfigId];

    const columnConfigDiff = Utilities.deepDiff(
      defaultColumnConfig,
      columnConfig
    );

    let serializedColumnConfigDiff;

    if (Utilities.isObjectEmpty(columnConfigDiff)) {
      serializedColumnConfigDiff = "";
    } else {
      serializedColumnConfigDiff = base64Encoder(columnConfigDiff);
    }

    $dm1.columnConfig = serializedColumnConfigDiff;

    $dm1.columnConfigId = $currentColumnConfigStore.columnConfigId;
    dispatch("changeColumnConfig", {
      payload: {
        columnConfig,
        columnConfigDiff
      }
    });
    columnsDialogOpen = false;
  }

  function handleThumbsGapChange(event) {
    const { payload } = event.detail;
    payload.forEach(column => {
      const { configName } = column;
      columnConfig[configName].width = column.width;
    });
  }

  function handleChangeInSpacing(event) {
    const { payload } = event.detail;
    columnConfig[payload.configName].align = payload.alignment;
    columnConfig[payload.configName].pL = payload.paddingLeft;
    columnConfig[payload.configName].pR = payload.paddingRight;
  }

  function calculateGapBetweenThumbs() {
    let destinationColumnIndex = undefined;
    gapBetweenThumbsInPercent = visibleColumns.map((column, index) => {
      if (column.name === "Destination") {
        destinationColumnIndex = index;
      }
      return parseInt(column.width);
    });

    if (gapBetweenThumbsInPercent.length === 0) return;
    let initialGapPercentageSum = gapBetweenThumbsInPercent.reduce(
      (acc, currentValue) => acc + currentValue
    );

    let gapPercentageDifference = initialGapPercentageSum - 100;

    if (initialGapPercentageSum !== 100) {
      gapBetweenThumbsInPercent[destinationColumnIndex] =
        gapBetweenThumbsInPercent[destinationColumnIndex] -
        gapPercentageDifference;
    }

    for (const i in visibleColumns) {
      visibleColumns[i].width = gapBetweenThumbsInPercent[i];
    }
    handleThumbsGapChange({
      detail: { payload: visibleColumns }
    });
  }

  function handleLabelChange(event) {
    const { payload } = event.detail;

    columnConfig[payload.configName].label = payload.label;
  }
</script>

<style>
  .list-item-disabled {
    color: #dddddd;
    cursor: not-allowed;
  }
</style>

<ModalDialog
  title="Advanced Column Settings"
  bind:active={columnsDialogOpen}
  okText="Save"
  okHandler={updateScreenColumnConfig}>
  <ColumnConfigComboBox
    {selectedColumnConfigPreset}
    {isDefaultColumnConfigChanged}
    selectName="Preset"
    on:changeInSelectedColumnConfigPreset={handleChangeInSelectedColumnConfigPreset} />

  <div class="tabs is-fullwidth">
    <ul>
      <li class:is-active={selectedTabIndex === 0}>
        <a on:click={() => (selectedTabIndex = 0)}>
          <span class="icon is-small">
            <i class="fas fa-exchange-alt" aria-hidden="true" />
          </span>
          Column Visiblility and Order
        </a>
      </li>
      <li class:is-active={selectedTabIndex === 1}>
        <a
          class={visibleColumns.length === 0 ? 'list-item-disabled' : ''}
          on:click={() => allowCustomColumnSettingsTab(1)}>
          <span class="icon is-small">
            <i class="fas fa-arrows-alt-h" />
          </span>
          Column Width
        </a>
      </li>
      <li class:is-active={selectedTabIndex === 2}>
        <a
          on:click={() => allowCustomColumnSettingsTab(2)}
          class={visibleColumns.length === 0 ? 'list-item-disabled' : ''}>
          <span class="icon is-small">
            <i class="fas fa-font" />
          </span>
          Column Text
        </a>
      </li>
    </ul>
  </div>

  {#if selectedTabIndex === 0}
    <ColumnsVisibilityAndOrder
      {selectedTabIndex}
      bind:columns
      bind:columnConfig />
  {:else if selectedTabIndex === 1}
    <ColumnsWidth
      fontColor={$dm1.fgColor || $dm1.defaultFgColor}
      backgroundColor={$dm1.bgColor || $dm1.defaultBgColor}
      headerBFgColor={{ headerBgColor: $dm1.headerBgColor, headerFgColor: $dm1.headerFgColor }}
      {visibleColumns}
      {gapBetweenThumbsInPercent}
      {selectedTabIndex}
      on:changeInThumbsGap={handleThumbsGapChange} />
  {:else if selectedTabIndex === 2}
    <ColumnsTextAndSpacing
      {selectedTabIndex}
      {visibleColumns}
      on:spacingChange={handleChangeInSpacing}
      on:labelChange={handleLabelChange} />
  {/if}
</ModalDialog>
