<script>
  import { onMount, createEventDispatcher } from "svelte";

  // input properties
  export let showSaveTemplatePopUp = false;

  // stores
  import { dm1 } from "./stores.js";

  // util
  import {
    serializeConfig,
    deserializeConfig
  } from "./config-serialization.js";
  import { getConfigId, saveConfig } from "./config-save-load.js";

  // api
  import {
    getEntityByUid,
    getChildConfigurations,
    updateEntity
  } from "./manager/screen-configuration-api.js";

  // reactive declarations
  $: childConfigurationsWithCheckboxes, updateGlobalCheckbox();

  // components
  import ModalDialog from "./widgets/ModalDialog.svelte";

  // model
  let uid = undefined;

  let childConfigurations = [];
  let childConfigurationsDmConfigs = {};
  let templateConfiguration = {};
  let templateConfigurationDmConfig = {};

  let childConfigurationsWithCheckboxes = [];

  let globalCheckbox = true;

  const dispatch = createEventDispatcher();

  function dispatchSaveMessage(msg) {
    dispatch("saveTemplateMsg", {
      msg
    });
  }

  // event handlers
  function cancelPopUp() {
    showSaveTemplatePopUp = false;
  }

  async function handleUpdateTemplate(updateMsg) {
    const checkedChildConfigurations = childConfigurationsWithCheckboxes
      .filter(config => config.checked)
      .map(config => config.id);

    let serializedConfig = window.location.hash;
    if (serializedConfig) {
      serializedConfig = serializedConfig.substring(1);
    }

    console.log("REQUEST OBJECT: ", checkedChildConfigurations);

    let failedUpdates = []
    let updatedScreensCount;

    try {
      const savedConfig = await saveConfig(
        templateConfiguration.uid,
        serializedConfig,
        checkedChildConfigurations,
        true
      );
      if (savedConfig.childrenUpdateResult) {
        failedUpdates = savedConfig.childrenUpdateResult.failedValues;
        updatedScreensCount = savedConfig.childrenUpdateResult.successCount;
      }

    } catch (err) {
      console.error(err);
    }

    showSaveTemplatePopUp = false;

    let msg =
      updateMsg ||
      `"${templateConfiguration.name}" ${
              updatedScreensCount > 0
          ? `and ${updatedScreensCount} screen configuration${
              updatedScreensCount > 1 ? "s" : ""
            } were updated`
          : "was updated"
      }`;

    if (failedUpdates && failedUpdates.length) {
      const failedValueStr = failedUpdates.join("\n");
      msg = msg + `. Failed to update ${failedUpdates.length} screens: ${failedValueStr}`;
    }

    dispatchSaveMessage(msg);
  }

  function toggleChildConfigurationCheckboxes() {
    globalCheckbox = !globalCheckbox;

    childConfigurationsWithCheckboxes.forEach((config, index) => {
      childConfigurationsWithCheckboxes[index].checked = globalCheckbox;
    });
  }

  function updateGlobalCheckbox() {
    const numberOfCheckedConfigurations = childConfigurationsWithCheckboxes.filter(
      config => config.checked
    );

    if (
      numberOfCheckedConfigurations.length ===
      childConfigurationsWithCheckboxes.length
    ) {
      globalCheckbox = true;
    } else {
      globalCheckbox = false;
    }
  }

  onMount(async () => {
    uid = getConfigId();

    templateConfiguration = await getEntityByUid(uid);
    childConfigurations = await getChildConfigurations(uid);

    childConfigurationsWithCheckboxes = childConfigurations.map(config =>
      Object.assign(config, { checked: true })
    );

    if (childConfigurationsWithCheckboxes.length === 0) {
      const msg = `"${templateConfiguration.name}" was updated`;

      await handleUpdateTemplate(msg);
    }
  });
</script>

{#if uid && childConfigurationsWithCheckboxes.length > 0}
  <ModalDialog
    active={showSaveTemplatePopUp}
    title="Which configurations should apply the changes of the template?"
    okText="Update"
    okHandler={handleUpdateTemplate}
    showCancel="true"
    cancelHandler={cancelPopUp}>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              bind:checked={globalCheckbox}
              on:click={toggleChildConfigurationCheckboxes} />
          </th>
          <th>Configuration name</th>
        </tr>
      </thead>
      <tbody>
        {#each childConfigurationsWithCheckboxes as childConfiguration, i}
          <tr>
            <td>
              <div class="childConfigurations">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    bind:checked={childConfiguration.checked} />
                </label>
              </div>
            </td>
            <td>{childConfiguration.name}</td>
          </tr>
        {/each}
      </tbody>
    </table>
  </ModalDialog>
{/if}
