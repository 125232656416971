<script>
  // core
  import { onMount } from "svelte";
  import { dm1 } from "../stores.js";

  // input properties
  export let visibleColumns = undefined;
  export let backgroundColor;
  export let fontColor;
  export let headerBFgColor;

  // model
  let tableWidth = undefined;

  function getLabelInLanguage(label) {
    return label[$dm1.lang];
  }

  onMount(() => {
    tableWidth = document.querySelector(".preview-table").clientWidth;
  });
</script>

<style>
  .width-preview {
    border-radius: 7px;
    border: 10px solid #000000;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.5);
  }
  table {
    width: 100%;
    table-layout: fixed;
  }
  table thead {
    border-bottom: solid 1px white;
  }

  table tr {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  table th {
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    vertical-align: middle;
  }
  table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    vertical-align: middle;
    height: 35px;
  }
</style>

<div class="width-preview">
  <table class="preview-table" style="background-color: {backgroundColor}">
    <thead
      style={headerBFgColor.headerBgColor ? `background-color: ${headerBFgColor.headerBgColor}` : ''}>
      <tr>
        {#each visibleColumns as column, i}
          <th
            title={getLabelInLanguage(column.label)}
            style="width: {(tableWidth * column.width) / 100}px; color: {headerBFgColor.headerFgColor || fontColor}">
            {getLabelInLanguage(column.label)}
          </th>
        {/each}
      </tr>
    </thead>
    <tbody style="">
      <tr>
        {#each visibleColumns as column, i}
          <td
            title="{column.width}%"
            style="width: {(tableWidth * column.width) / 100}px; color: {fontColor}">
            {column.width}%
          </td>
        {/each}
      </tr>
    </tbody>
  </table>
</div>
