<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  // components
  import ColumnsTableRow from "./ColumnsTableRow.svelte";
  import ColumnsWidthPreview from "./ColumnsWidthPreview.svelte";
  import Slider from "../widgets/Slider.svelte";

  export let visibleColumns = [];
  export let selectedTabIndex;
  export let gapBetweenThumbsInPercent = undefined;
  export let backgroundColor;
  export let fontColor;
  export let headerBFgColor;

  function handleChangeInThumbsGap(e) {
    const { payload } = e.detail;

    for (const i in visibleColumns) {
      visibleColumns[i].width = payload[i];
    }
    dispatch("changeInThumbsGap", {
      payload: visibleColumns
    });
  }
</script>

<style>
  .preview-width-slider {
    margin-bottom: 2rem;
  }
</style>

{#if gapBetweenThumbsInPercent}
  <Slider
    {gapBetweenThumbsInPercent}
    on:changeInThumbsGap={handleChangeInThumbsGap} />
  <div class="preview-width-slider">
    <ColumnsWidthPreview
      {visibleColumns}
      {backgroundColor}
      {fontColor}
      {headerBFgColor} />
  </div>
{/if}
