<script>
  // components
  import GroupPanel from "./widgets/GroupPanel.svelte";
  import ModalDialog from "./widgets/ModalDialog.svelte";
  import ColumnsDialog from "./columns/ColumnsDialog.svelte";

  // stores
  import { dm1, dmInitial } from "./stores.js";
  import { columnConfigStore } from "./store-column-config.js";
  import { userLoggedInStore } from "./store-user.js";

  // enum
  import { AdvancedColumnConfigLayoutType } from "./manager/enum/advancedColumnConfigLayoutTypeEnum.js";

  // utils
  import { Utilities } from "./utils.js";

  const dm = $dm1;
  let columnConfig = undefined;

  $: {
    dm1.set(dm);
  }

  // resets back some options set by the single departure layout
  function setUiOptionsToMultipleDepartures() {
    if (dm.group1numDepartures === 1) {
      dm.group1numDepartures = dmInitial.group1numDepartures;
    }
    if (dm.uiRatio === "1645") {
      dm.uiRatio = "169";
    }
  }

  function selectLayout(newSelectedLayout) {
    const oldSelectedLayout = dm1.uiSelectedLayout;
    if (
      newSelectedLayout === "trainSingleDeparture" ||
      newSelectedLayout === "trainMultipleDepartures"
    ) {
      dm.columnConfigId = "notSupported";
      dm.columnConfig = "";
      dm.stationHeaderPos = "left";
      dm.uiSelectedLayout = newSelectedLayout;
    } else {
      if (oldSelectedLayout !== newSelectedLayout) {
        dm.columnConfigId = "";
        dm.columnConfig = "";
        dm.uiSelectedLayout = newSelectedLayout;
      }
    }
  }

  $: if (dm.uiSelectedLayout === "oneColumn") {
    dm.mainLayout = "";
    dm.contentLayout = "basic";
    dm.splitTwoColumns = false;
    dm.showVia = false;
    setUiOptionsToMultipleDepartures();
  } else if (dm.uiSelectedLayout === "twoColumns") {
    dm.mainLayout = "";
    dm.contentLayout = "basic";
    dm.splitTwoColumns = true;
    dm.showVia = false;
    setUiOptionsToMultipleDepartures();
  } else if (dm.uiSelectedLayout === "trainMultipleDepartures") {
    dm.mainLayout = "";
    dm.contentLayout = "trainMultipleDepartures";
    dm.splitTwoColumns = false;
    setUiOptionsToMultipleDepartures();
  } else if (dm.uiSelectedLayout === "trainSingleDeparture") {
    dm.mainLayout = "posSimple";
    dm.contentLayout = "trainSingleDeparture";
    dm.splitTwoColumns = false;
    dm.showTopHeader = false;
    dm.showFooter = false;
    dm.showVia = false;
    dm.group1numDepartures = 1;
    dm.uiRatio = "1645";
  }
</script>

<GroupPanel title="Layout Type" panelBodyAlignment="center">
  <div style="display: inline-block; margin-bottom: 2.5%; text-align: center;">
    <a
      class:is-active={dm.uiSelectedLayout === 'oneColumn'}
      class="box wemlin-layout-box is-pulled-left has-text-centered"
      on:click={() => selectLayout('oneColumn')}>
      <nav class="level">
        <div class="level-item">
          <figure class="image is-48x48">
            <img src="img/layout-icons/layout1.png" alt="" />
          </figure>
        </div>
      </nav>
      Basic Layout
    </a>

    <a
      class:is-active={dm.uiSelectedLayout === 'twoColumns'}
      class="box wemlin-layout-box is-pulled-left has-text-centered"
      on:click={() => selectLayout('twoColumns')}>
      <nav class="level">
        <div class="level-item">
          <figure class="image is-48x48">
            <img src="img/layout-icons/layout2.png" alt="" />
          </figure>
        </div>
      </nav>
      Two Column
    </a>

    <a
      class:is-active={dm.uiSelectedLayout === 'trainSingleDeparture'}
      class="box wemlin-layout-box is-pulled-left has-text-centered"
      on:click={() => selectLayout('trainSingleDeparture')}>
      <nav class="level">
        <div class="level-item">
          <figure class="image is-48x48">
            <img src="img/layout-icons/trainSingleDeparture.png" alt="" />
          </figure>
        </div>
      </nav>
      Track Layout
    </a>

    {#if $userLoggedInStore}
      <a
        class:is-active={dm.uiSelectedLayout === 'trainMultipleDepartures'}
        class="box wemlin-layout-box is-pulled-left has-text-centered"
        on:click={() => selectLayout('trainMultipleDepartures')}
        title="The Train Layout will be removed in the next version. Please
        change all your train screen configurations to 'Basic layout' and adjust
        the columns with the 'Advanced Column Settings' option in the first tab.">
        <nav class="level">
          <div class="level-item">
            <figure class="image is-48x48">
              <img src="img/layout-icons/trainMultipleDepartures.png" alt="" />
            </figure>
          </div>
        </nav>
        Train Layout (Deprecated)
      </a>
    {/if}
  </div>
</GroupPanel>
