import "url-search-params-polyfill";
import { getEntityByUid, updateEntity } from "./manager/screen-configuration-api.js";
import { Utilities as U } from "./utils.js";
import { getQueryStringParameter } from "./util/url-search-util.js";

// Exceptions
import {
  EntityNotFoundError,
  EntityNotModifiedError
} from "./util/error-utils";

export function getConfigId(location) {
  let id = undefined;

  if (typeof window !== "undefined") {
    const search = window.location.search;

    id = getQueryStringParameter(search, "c");
    console.log("Parsed config ID from URL query: " + id);
    return id;
  }
}

export async function loadConfig(configId) {
  try {
    const screenConfigurationEntity = await getEntityByUid(configId);
    console.log("Loaded: " + JSON.stringify(screenConfigurationEntity));
    return screenConfigurationEntity;
  } catch (err) {
    console.error(err);
    throw new EntityNotFoundError("Screen configuration could not be found");
  }
}


export async function saveConfig(configId, serializedConfig, childConfigurationsIds, includeChildUpdateResult = false) {
  console.log(
    "Saving config with ID: " + configId + ", serializedDmConfig: " + serializedConfig
  );
  const entity = {
    serializedDmConfig: serializedConfig
  };
  return await updateConfig(configId, entity, childConfigurationsIds, includeChildUpdateResult);
}

export async function updateConfig(configId, entity, childConfigurationsIds, includeChildUpdateResult = false) {
  console.log("Saving config with ID: " + configId);

  let screenConfigurationEntity;
  try {
    screenConfigurationEntity = await getEntityByUid(configId);
  } catch (err) {
    console.error(err);
    throw new EntityNotFoundError("Screen configuration with id " + configId + " was not found");
  }
  console.log("Loaded: " + JSON.stringify(screenConfigurationEntity));

  if (!screenConfigurationEntity || !screenConfigurationEntity.id) {
    throw new EntityNotFoundError("Screen configuration with id " + configId + " was not found");
  }

  const updatedEntity = U.mergeShallowRight(screenConfigurationEntity, entity);
  try {
    const result = await updateEntity(updatedEntity, childConfigurationsIds, includeChildUpdateResult);
    return result;
  } catch (err) {
    console.error(err);
    throw new EntityNotModifiedError("Screen configuration " + configId + "could not be updated");
  }
}



