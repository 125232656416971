<script>
  import { createEventDispatcher } from "svelte";

  // components
  import DisplaySaveTemplateConfigurationPopUp from "./DisplaySaveTemplateConfigurationPopUp.svelte";
  import ModalDialog from "./widgets/ModalDialog.svelte";

  // enums
  import { ConfigurationTypeEnum } from "./manager/enum/configurationTypeEnum.js";

  import { EntityNotFoundError } from "./util/error-utils.js";

  // functions
  import { saveConfig, loadConfig } from "./config-save-load.js";

  import {
    createEntity,
    baseEntityModel
  } from "./manager/screen-configuration-api.js";

  import { dm1 } from "./stores.js";

  // input properties
  export let loadedConfig;
  export let userLoggedIn;

  let stagedConfigurationTitle;

  const dispatch = createEventDispatcher();

  // show modals
  let showSaveConfigurationModal = false;
  let showSaveTemplatePopUp = false;

  async function handleSaveConfigClick() {
    if (!userLoggedIn) {
      dispatch("showModal", {
        text: "Please log in to save this configuration."
      });
      return;
    }

    if (loadedConfig.type === ConfigurationTypeEnum.NORMAL) {
      if (!checkIfStationSelected()) {
        dispatch("showModal", {
          text: "Please select a station before you save this configuration."
        });
        return;
      }
    }

    console.log("Saving config with id: " + loadedConfig.uid);

    if (loadedConfig.uid) {
      // existing config
      if (loadedConfig.type === ConfigurationTypeEnum.TEMPLATE) {
        // existing template config
        showSaveTemplatePopUp = true;
      } else {
        // existing normal config
        handleSaveExistingConfig();
      }
    } else {
      // new config
      stagedConfigurationTitle = loadedConfig.name;
      showSaveConfigurationModal = true;
    }
  }

  async function handleSaveExistingConfig() {
    let serializedConfig = window.location.hash;
    if (serializedConfig) {
      serializedConfig = serializedConfig.substring(1);
    }

    let updatedConfig;
    try {
      updatedConfig = await saveConfig(loadedConfig.uid, serializedConfig);

      dispatch("showNotification", {
        success: true,
        text: `Configuration "${updatedConfig.name}" saved.`
      });
    } catch (err) {
      console.error(err);
      let errMsg;
      if (err instanceof EntityNotFoundError) {
        errMsg = "Could not save configuration, not found. Please try again.";
      } else {
        errMsg = "Could not save configuration. Please try again.";
      }

      dispatch("showNotification", {
        success: false,
        text: errMsg
      });
    }
  }

  async function handleSaveConfigFromModalClick() {
    // do not allow empty configuration name
    if (!stagedConfigurationTitle || stagedConfigurationTitle.trim() === "") {
      return;
    }

    showSaveConfigurationModal = false;

    let serializedConfig = window.location.hash;
    if (serializedConfig) {
      serializedConfig = serializedConfig.substring(1);
    }

    console.log("Template ID: ", $dm1.uiFromTemplateId);
    let parentConfig;
    if ($dm1.uiFromTemplateId) {
      console.log("Load parent config: " + $dm1.uiFromTemplateId);
      try {
        parentConfig = await loadConfig($dm1.uiFromTemplateId);
      } catch (err) {
        console.error(err);
        dispatch("showNotification", {
          success: false,
          text: "Could not find template for the configuration"
        });

        return;
      }
    }

    const entity = Object.assign({}, baseEntityModel);
    entity.type = loadedConfig.type;
    entity.name = stagedConfigurationTitle;
    entity.serializedDmConfig = serializedConfig;

    if (parentConfig && parentConfig.id) {
      entity.parentConfiguration = parentConfig;
    }

    delete entity.id;
    delete entity.uid;
    if (entity.parentConfiguration) {
      delete entity.parentConfiguration.infoMessages;
    }

    try {
      const createdEntity = await createEntity(entity);
      window.location.href = `index.html?c=${createdEntity.uid}`;
    } catch (err) {
      console.error(err);
      dispatch("showNotification", {
        success: false,
        text: "Could not create new Screen Configuration"
      });
    }
  }

  function checkIfStationSelected() {
    return !$dm1.group1stationId ? false : true;
  }

  function handleOnUpdateChildConfigurationsNotification(event) {
    dispatch("showNotification", {
      success: true,
      text: event.detail.msg
    });
  }
</script>

{#if showSaveTemplatePopUp}
  <DisplaySaveTemplateConfigurationPopUp
    bind:showSaveTemplatePopUp
    on:saveTemplateMsg={handleOnUpdateChildConfigurationsNotification} />
{/if}
<div class="navbar-item has-dropdown is-hoverable">
  <a class="navbar-link is-arrowless" on:click={handleSaveConfigClick}>
    <span class="icon is-small">
      <i class="fas fa-save" />
    </span>
    Save
  </a>
</div>

<ModalDialog
  title="New Screen {loadedConfig.type === ConfigurationTypeEnum.NORMAL ? 'Configuration' : 'Template'}"
  bind:active={showSaveConfigurationModal}
  okText="Save"
  showCancel="true"
  okHandler={handleSaveConfigFromModalClick}>
  <div class="field is-horizontal">
    <div class="field-label">
      <label class="label">Name</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder=""
            bind:value={stagedConfigurationTitle}
            on:click={e => (e.target.value = '')} />
        </div>
      </div>
    </div>
  </div>
</ModalDialog>
