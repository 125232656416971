<script>
  import GroupPanel from "./widgets/GroupPanel.svelte";

  import { dm1 } from "./stores.js";
  import { dmBasic } from "./store-basic.js";
  import { userLoggedInStore } from "./store-user.js";
</script>

<GroupPanel title="Display Settings" hidden>
  <div>

    <div class="field">
      <div class="control">
        <label class="label is-small">Language</label>
        <div class="select is-fullwidth">
          <select bind:value={$dm1.lang}>
            {#each $dmBasic.supportedLanguages as supportedLanguage}
              <option value={supportedLanguage.name}>
                {supportedLanguage.label}
              </option>
            {/each}
          </select>
        </div>

      </div>
    </div>

    <div class="field">
      <div class="control">
        <input
          class="is-checkradio"
          id="animatedDisruptionMessages"
          type="checkbox"
          disabled={$dm1.contentLayout === 'trainSingleDeparture'}
          bind:checked={$dm1.animatedDisruptionMessages} />
        <label
          class="label"
          for="animatedDisruptionMessages"
          title={$dm1.contentLayout === 'trainSingleDeparture' ? 'Disruption messages are not supported in the current layout' : ''}>
          Animated Disruption Messages
        </label>
      </div>
    </div>

    {#if $userLoggedInStore}
      <div class="field">
        <div class="control">
          <input
            class="is-checkradio"
            id="showConfiguratorCheckbox"
            type="checkbox"
            disabled={$dm1.contentLayout === 'trainSingleDeparture'}
            bind:checked={$dm1.showConfigurator} />
          <label
            class="label"
            for="showConfiguratorCheckbox"
            title={$dm1.contentLayout === 'trainSingleDeparture' ? 'Built-in configurator is not supported in the current layout' : ''}>
            Show built-in Configurator
          </label>
        </div>
      </div>
    {/if}
  </div>
</GroupPanel>
