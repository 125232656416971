import "url-search-params-polyfill";

import { dm1, dmInitial } from "./stores.js";
import { deserializeConfig, serializeConfig } from "./config-serialization.js";
import { getConfigId, loadConfig } from "./config-save-load.js";

import Navbar from "./Navbar.svelte";
import LeftMenu from "./LeftMenu.svelte";
import ScreenPreview from "./ScreenPreview.svelte";
import Footer from "./Footer.svelte";

import { ConfigurationTypeEnum } from "./manager/enum/configurationTypeEnum.js";

import {
  hasQueryParameter,
  removeQueryStringParameters
} from "./util/url-search-util";

const baseNewConfigNormal = {
  name: "Untitled Configuration",
  type: ConfigurationTypeEnum.NORMAL
};

const baseNewConfigTemplate = {
  name: "Untitled Template",
  type: ConfigurationTypeEnum.TEMPLATE
};

let loadedConfig = undefined;

const navbar = new Navbar({
  target: document.querySelector("#topContainer"),
  props: {
    loadedConfig: loadedConfig,
  }
});

const leftMenu = new LeftMenu({
  target: document.querySelector("#leftContainer"),
  props: {
    loadedConfig: loadedConfig
  }
});

const screenPreview = new ScreenPreview({
  target: document.querySelector("#rightContainer"),
  props: {
    loadedConfig: loadedConfig
  }
});

const footer = new Footer({
  target: document.querySelector("#bottomContainer"),
  props: {
  }
});


let dm = {};
const configId = getConfigId();
let isTemplate = false;
if (hasQueryParameter("isTemplate")) {
  isTemplate = true;
}

let fromTemplate = false;
if (hasQueryParameter("fromTemplate")) {
  fromTemplate = true;
}

if (configId) {
  console.log(
    "Config ID present in URL: " + configId + ", loading config from DB"
  );
  loadConfigFromDb();
} else {
  if (isTemplate) {
    loadedConfig = baseNewConfigTemplate;
  } else {
    loadedConfig = baseNewConfigNormal;
  }
  setComponentProperties();
}

async function loadConfigFromDb() {
  let configEntity;
  try {
    configEntity = await loadConfig(configId);
  } catch (err) {
    configEntity = undefined;
    console.log("Error: screen configuration could not be loaded", err);
  }

  if (configEntity) {
    loadedConfig = configEntity;
    console.log("Loaded config from DB: " + JSON.stringify(loadedConfig));
    const serializedConfig = loadedConfig.serializedDmConfig;
    console.log(
      "Config from DB: " + loadedConfig.uid + ", value: " + serializedConfig
    );

    deserializeConfig(serializedConfig, dmInitial, dm);
    if (fromTemplate) {
      dm.uiFromTemplateId = configId;
      loadedConfig = baseNewConfigNormal;
      // replace station properties from the template with default values
      Object.keys(dm).forEach(dmConfigProperty => {
        if (dmConfigProperty.startsWith("group")) {
          dm[dmConfigProperty] = dmInitial[dmConfigProperty];
        }
      });
      removeQueryStringParameters(["c", "fromTemplate"]);
    }
    console.log("Updating store config from deserialized config from DB: ", dm);
    dm1.set(dm);

    document.title = loadedConfig.name + " - Wemlin Departure Monitor Configurator";
  } else {
    loadedConfig = baseNewConfigNormal;
  }

  setComponentProperties();
}

function setComponentProperties() {
  navbar.$set({
    loadedConfig: loadedConfig
  });

  screenPreview.$set({ loadedConfig: loadedConfig });
  leftMenu.$set({ loadedConfig });
}

let firstLoad = true;

dm1.subscribe(updatedDm => {
  console.log("Config updated");
  dm = updatedDm;

  if (typeof window !== "undefined") {
    // skip first update of config when it is not actually modified yet
    if (!firstLoad) {
      const serializedConfig = serializeConfig(
        updatedDm,
        dmInitial,
        true,
        true
      );
      console.log("Updating hashParams: " + serializedConfig);

      const newHash = "#" + serializedConfig;

      // if (history && history.replaceState) {
      //   history.pushState(null, null, newHash);
      // } else {
      if (window.location.hash != newHash) {
        window.location.hash = newHash;
      }
      // }

    } else {
      firstLoad = false;
    }
  }
});

if (typeof window === "undefined") {
  console.log("window undefined");
} else {
  // load state from URL hash
  const location = window.location;
  // console.log("location: " + JSON.stringify(location));
  const hash = location.hash;
  // console.log("hash: " + hash);

  let serializedConfig;
  if (hash && hash.length > 1) {
    serializedConfig = hash.substr(1);
    console.log("Loading config params from hash");
  } else {
    if (loadedConfig) {
      serializedConfig = loadedConfig.serializedDmConfig;
      console.log("Loading config params from stored DB config");
    }
  }

  if (serializedConfig) {
    deserializeConfig(serializedConfig, dmInitial, dm);
    console.log("Updating store config from deserialized config");
    dm1.set(dm);
  }
}
