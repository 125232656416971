import { dmBasicInitial } from "../../store-basic.js";
import * as httpClient from "../../api";
import { createFilterQueryUrl } from "../../util/query-utils.js";
import { isError } from "../../util/http-utils.js";
import {
  EntityNotCreatedError,
  UniqueEntityAttributeError,
} from "../../util/error-utils";

const apiUrl = dmBasicInitial.managmentApiRootUrl;

const baseApiParamModel = {
  name: {
    separate: false,
    queryName: "name",
    filterType: "contains",
  },
  group: {
    separate: false,
    queryName: "groupIds",
    filterType: "in"
  },
  page: {
    separate: true,
    queryName: "page",
  },
  size: {
    separate: true,
    queryName: "size",
  },
  sort: {
    separate: true,
    queryName: "sort",
  },
};

export async function getAllSegmentedScreens(queryParams) {
  const query = createFilterQueryUrl(baseApiParamModel, queryParams);
  console.log("getAllSegmentedScreens.query", query);
  return httpClient.get(`${apiUrl}segmented-screens?${query}`);
}

export async function getSegmentedScreensCount(criteria) {
  const filterQuery = createFilterQueryUrl(baseApiParamModel, criteria);
  return httpClient.get(`${apiUrl}segmented-screens/count?${filterQuery}`);
}

export async function getLatestSegmentedScreens(limit = 10) {
  const query = createFilterQueryUrl(baseApiParamModel, {
    page: 0,
    size: limit,
    sort: "dateCreated,desc"
  });
  return httpClient.get(`${apiUrl}segmented-screens/uid-name?${query}`);
}

export async function getSegmentedScreen(uid, eTag) {
  const response = await httpClient.get(`${apiUrl}segmented-screens/uid/${uid}`, eTag);
  if (eTag && response.status && response.status == 304) {
    // not modified
    return undefined;
  }

  if (!response || isError(response)) {
    throw new Error(`Could not fetch entity { uid = ${uid} }`);
  }
  return response;
}

export async function deleteSegmentedScreen(id) {
  return httpClient.del(`${apiUrl}segmented-screens/${id}`);
}

export async function saveSegmentedScreen(segmentedScreen) {
  const body = {
    uid: segmentedScreen.id,
    name: segmentedScreen.name
  };

  if (segmentedScreen.serializedConfig) {
    body.serializedConfig = segmentedScreen.serializedConfig;
  }

  if (segmentedScreen.groups) {
    body.groupIds = segmentedScreen.groups.map((g) => g.id);
  }

  const action = segmentedScreen.id ? httpClient.put : httpClient.post;

  const response = await action(`${apiUrl}segmented-screens`, body);
  if (isError(response)) {
    if (response.reason && response.reason === "UNIQUE_ATTRIBUTE_VIOLATION") {
      throw new UniqueEntityAttributeError(response.message);
    }
    throw new EntityNotCreatedError(
      "Could not save segmented screen configuration"
    );
  }

  return response;
}

export async function deleteAllSegmentedScreens(segmentedScreenIds) {
  console.log("deleteAllSegmentedScreens", segmentedScreenIds);
  const idsParam = segmentedScreenIds.join(',');
  const url = `${apiUrl}segmented-screens?ids=${idsParam}`;
  return httpClient.del(url);
}

export async function getAllScreenGroups() {
  return httpClient.get(`${apiUrl}screen-groups?unpaged=true`);
}

export async function createScreenGroup(name) {
  const url = `${apiUrl}screen-groups`;
  return httpClient.post(url, {
    name,
  });
}
