<script>
  import { dm1 } from "./stores.js";

  import { fade } from "svelte/transition";

  import { onMount } from "svelte";

  import LayoutSettingsGroup from "./LayoutSettingsGroup.svelte";
  import HeaderFooterSettingsGroup from "./HeaderFooterSettingsGroup.svelte";
  import StationsSettingsGroup from "./StationsSettingsGroup.svelte";
  import ColumnsSettingsGroup from "./ColumnsSettingsGroup.svelte";
  import DataSettingsGroup from "./DataSettingsGroup.svelte";
  import DisplaySettingsGroup from "./DisplaySettingsGroup.svelte";
  import StylesSettingsGroup from "./StylesSettingsGroup.svelte";

  export let loadedConfig;

  let showInitialNotification = true;

  let leftContainerWidth = 0;

  $: if (
    document.getElementById("leftContainer").clientWidth !== leftContainerWidth
  ) {
    leftContainerWidth =
      document.getElementById("leftContainer").clientWidth - 13;
  }

  const tab1 = 1;
  const tab2 = 2;
  const tab3 = 3;

  onMount(async () => {
    setTimeout(() => (showInitialNotification = undefined), 8000);
  });
</script>

<style>
  .template-note {
    padding: 13px;
    bottom: 0;
    position: fixed;
    margin-bottom: 0px;
    z-index: 99;
  }

  .notification {
    background-color: rgb(255, 221, 87, 0.8);
  }
</style>

<div id="leftMenuTabs" class="tabs is-fullwidth">
  <ul>
    <li class:is-active={$dm1.uiSelectedTabIndex <= tab1}>
      <a on:click={() => ($dm1.uiSelectedTabIndex = tab1)}>
        <span class="icon is-small">
          <i class="fas fa-th-large" aria-hidden="true" />
        </span>
        Stations
      </a>
    </li>
    <li class:is-active={$dm1.uiSelectedTabIndex === tab2}>
      <a on:click={() => ($dm1.uiSelectedTabIndex = tab2)}>
        <span class="icon is-small">
          <i class="fas fa-bus-alt" />
        </span>
        Layout
      </a>
    </li>
    <li class:is-active={$dm1.uiSelectedTabIndex === tab3}>
      <a on:click={() => ($dm1.uiSelectedTabIndex = tab3)}>
        <span class="icon is-small">
          <i class="fas fa-palette" />
        </span>
        Styles
      </a>
    </li>
  </ul>

</div>

<div id="leftMenuContainer">
  {#if $dm1.uiSelectedTabIndex <= tab1}
    <StationsSettingsGroup {loadedConfig} />
    <ColumnsSettingsGroup {loadedConfig} />
  {:else if $dm1.uiSelectedTabIndex === tab2}
    <LayoutSettingsGroup />
    <DisplaySettingsGroup />
    <HeaderFooterSettingsGroup />
  {:else if $dm1.uiSelectedTabIndex === tab3}
    <StylesSettingsGroup />
  {/if}
  {#if loadedConfig && loadedConfig.parentConfiguration && showInitialNotification}
    <div
      transition:fade
      class="template-note container"
      style="width: {leftContainerWidth}px;">
      <div class="notification">
        <button
          class="delete"
          on:click={() => (showInitialNotification = false)} />
        Note: This configuration has been derived from a template configuration:
        <a href="?c={loadedConfig.parentConfiguration.uid}">{loadedConfig.parentConfiguration.name}</a>
      </div>
    </div>
  {/if}
</div>
