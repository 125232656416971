import "url-search-params-polyfill";

export function getQueryStringParameter(queryString, queryParam) {
  if (queryString) {
    const param = new URLSearchParams(queryString);
    return param.get(queryParam);
  } else {
    return undefined;
  }
}

export function getQueryStringParameters(queryString) {
  const params = new URLSearchParams(queryString);
  const result = {};
  for (const keyAndValue of params.entries()) {
    const key = keyAndValue[0];
    const value = keyAndValue[1];
    result[key] = value;
  }
  return result;
}

export function removeQueryStringParameters(params, queryString) {
  if (!queryString) {
    queryString = window.location.search;
  }

  const search = new URLSearchParams(queryString);

  params.forEach(param => {
    if (search.has(param)) {
      search.delete(param);
    }
  });

  if (search.toString() === "?") {
    return window.history.replaceState(null, null, "/");
  } else {
    return window.history.replaceState(null, null, `?${search.toString()}`);
  }
}

export function hasQueryParameter(param, queryString) {
  if (!queryString) {
    queryString = window.location.search;
  }

  const search = new URLSearchParams(queryString);

  return search.has(param);
}

export function queryParametersToObject(queryString) {
  const searchParams = new URLSearchParams(queryString);
  return Object.fromEntries(searchParams);
}
