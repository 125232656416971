import { dmBasic } from "./store-basic.js";
import { authTokenStore, getAuthToken } from "./store-user.js";
import { send } from "./http-client.js";

let apiUseJsonp = false;
dmBasic.subscribe(updatedDmBasic => {
  apiUseJsonp = updatedDmBasic.apiUseJsonp;
});

let authTokenValue;
const unsubscribe = authTokenStore.subscribe(value => {
  console.log("Authorization Token updated: " + value);
  authTokenValue = value ? "Bearer " + value : undefined;
});

function loadAuthTokenFromLocalStorageIfNeeded() {
  if (!authTokenValue) {
    getAuthToken();
  }
}

function sendInternal(params) {
  const promise = send(params);

  // TODO pst: use exceptions here instead of returing undefined if the request fails
  // promise = promise.catch(function (err) {
  //   console.error("Error while making request: " + err);
  //   if (err instanceof NotAuthorizedError) {
  //     logout();
  //   }
  //   throw (err);
  // });

  return promise;
}


export function getWithOptionalJsonP(url) {
  loadAuthTokenFromLocalStorageIfNeeded();
  return sendInternal({ method: "GET", url, token: authTokenValue, useJSONP: apiUseJsonp });
}

export function get(url, eTag) {
  loadAuthTokenFromLocalStorageIfNeeded();
  return sendInternal({ method: "GET", url, token: authTokenValue, eTag: eTag });
}

export function getWithToken(url, token) {
  loadAuthTokenFromLocalStorageIfNeeded();
  return sendInternal({ method: "GET", url, token: token });
}


export function del(url) {
  loadAuthTokenFromLocalStorageIfNeeded();
  return sendInternal({ method: "DELETE", url, token: authTokenValue });
}

export function post(url, data) {
  loadAuthTokenFromLocalStorageIfNeeded();
  return sendInternal({ method: "POST", url, data, token: authTokenValue });
}

export function put(url, data) {
  loadAuthTokenFromLocalStorageIfNeeded();
  return sendInternal({ method: "PUT", url, data, token: authTokenValue });
}


export function openInNewTab(url) {
  loadAuthTokenFromLocalStorageIfNeeded();
  window.open(url + '&access_token=' + authTokenValue.substring("Bearer ".length), "_blank");
}
