<script>
  import { onMount } from "svelte";

  // components
  import ModalDialog from "./widgets/ModalDialog.svelte";
  import LoginFormModal from "./login/LoginFormModal.svelte";

  // stores
  import { dmBasic } from "./store-basic.js";
  import { userStore, authTokenStore, logout } from "./store-user.js";

  // input properties
  export let showLoginFormOnMount = false;

  // internal properties
  $: userLoggedIn = $authTokenStore != undefined && $userStore != undefined;

  // show modals
  let showRegisterFormModal = false;
  let showProfileModal = false;

  onMount(async () => {
    if (!userLoggedIn && showLoginFormOnMount && loginFormModalComponent) {
      console.log("showLoginForm onMount");
      loginFormModalComponent.showLoginForm();
    }
  });

  let loginFormModalComponent;

  authTokenStore.subscribe(updatedAuthToken => {
    if (updatedAuthToken === undefined) {
      if (showLoginFormOnMount && loginFormModalComponent) {
        console.log("showLoginForm onMount");
        loginFormModalComponent.showLoginForm();
      }
    }
  });
</script>

{#if userLoggedIn}
  <div class="navbar-item has-dropdown is-hoverable">
    <a
      class="navbar-link"
      title={$userStore.firstName + ' ' + $userStore.lastName}>
      <span class="icon is-small">
        <i class="fas fa-user" />
      </span>

      <span
        class="truncate-text"
        style="margin-left: 0.3rem; max-width: 150px;">
        {$userStore.login}
      </span>
    </a>

    <div class="navbar-dropdown">
      <a
        class="navbar-item"
        on:click={() => (showProfileModal = !showProfileModal)}>
        Profile
      </a>
      <hr class="navbar-divider" />
      <a
        class="navbar-item"
        on:click={() => {
          logout();
          window.location = 'index.html';
        }}>
        Logout
      </a>
    </div>
  </div>
{:else}
  <div class="navbar-item">
    <div class="buttons">
      <a
        class="button is-primary"
        on:click={() => (showRegisterFormModal = !showRegisterFormModal)}>
        Register
      </a>
      <a
        class="button is-light"
        on:click={() => loginFormModalComponent.showLoginForm()}>
        Log in
      </a>
    </div>
  </div>
{/if}

<LoginFormModal bind:this={loginFormModalComponent} />

<ModalDialog
  title="Registration not available"
  bind:active={showRegisterFormModal}
  width="600">
  <div class="content">
    <p>
      Wemlin Departure Monitor Configurator is in public beta and currenly only
      available for existing users.
    </p>
    <p>
      If you are interested in using it for your departure monitors please
      <a
        href="https://www.netcetera.com/en/product-detail/products/wemlin/Wemlin-POS.html"
        target="_blank">
        contact us
      </a>
    </p>
  </div>
</ModalDialog>

{#if showProfileModal}
  <ModalDialog title="Profile" bind:active={showProfileModal} width="600">

    {#if $userStore}
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Username</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                readonly="true"
                value={$userStore.login}
                placeholder="" />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Name</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                readonly="true"
                value={$userStore.firstName + ' ' + $userStore.lastName}
                placeholder="" />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Email</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                readonly="true"
                value={$userStore.email}
                placeholder="" />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Organization</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                readonly="true"
                value={$userStore.organizationName}
                placeholder="" />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">API Network</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                readonly="true"
                value={($userStore.transportNetwork ? $userStore.transportNetwork.apiNetwork : '') + '  (using ' + $dmBasic.network + ')'}
                placeholder="" />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">API Root URL</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                readonly="true"
                value={($userStore.transportNetwork ? $userStore.transportNetwork.apiRootUrl : '') + '  (using ' + $dmBasic.apiRootUrl + ')'}
                placeholder="" />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">POS URL</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                readonly="true"
                value={($userStore.transportNetwork ? $userStore.transportNetwork.posRootUrl : '') + '  (using ' + $dmBasic.posRootUrl + ')'}
                placeholder="" />
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">User roles</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                style=" text-transform: capitalize;"
                readonly="true"
                value={$userStore.authorities && $userStore.authorities.length > 0 ? $userStore.authorities
                      .filter(role => role && role.startsWith('ROLE_'))
                      .map(role => role
                          .substring(5)
                          .toLowerCase()
                          .replaceAll('_', ' '))
                      .join(', ') : ''}
                placeholder="" />
            </div>
          </div>
        </div>
      </div>
    {:else}
      <p>Please log in to see user profile.</p>
    {/if}

  </ModalDialog>
{/if}
