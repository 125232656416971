import * as httpClient from "../api";

export async function loadStations(stationsUrl, apiRootUrl, network, limit, filterText) {
    let url = stationsUrl.supplant({
        apiRootUrl: apiRootUrl,
        network: network
    });

    url = url + '?';
    if (limit) {
        url = url + `&limit=${limit}`;
    }
    if (filterText) {
        url = url + `&name=${filterText}`;
    }

    const stationsJson = await httpClient.getWithOptionalJsonP(url);
    const stations = stationsJson.stations;

    return stations;
}

export async function loadStation(stationsUrl, apiRootUrl, network, stationId) {
    console.log("loadStation " + stationId);
    let url = stationsUrl.supplant({
        apiRootUrl: apiRootUrl,
        network: network
    });

    url = url + '?id=' + stationId;

    const stationsJson = await httpClient.getWithOptionalJsonP(url);
    const stations = stationsJson.stations;

    return stations;
}

export function findStation(stations, stationIdToFind) {
    console.log("findStation " + stationIdToFind);

    let result = undefined;
    if (stations && stationIdToFind) {
        result = stations.find(station => station.referenceId === stationIdToFind);
    }
    return result;
}