<script>
  export let title = "Group Title";

  export let hidden = false;
  export let panelBodyAlignment = undefined;

  function toggleSectionVisiblity(event) {
    hidden = !hidden;
  }
</script>

<div class="panel wemlin-panel">
  <div
    class="panel-heading wemlin-panel-heading is-radiusless"
    on:click={toggleSectionVisiblity}>
    <div class="level">
      <div class="level-left">
        <p class="level-item">{title}</p>
      </div>
      <div class="level-right">
        <a class="level-item">
          <span class="icon is-small">
            <i
              class={hidden ? 'fas fa-chevron-down' : 'fas fa-chevron-up'}
              aria-hidden="true" />
          </span>
        </a>
      </div>
    </div>
  </div>

  <div
    class="panel-heading wemlin-panel-body is-radiusless is-clearfix"
    style={panelBodyAlignment ? `text-align: ${panelBodyAlignment}` : ''}
    {hidden}>

    <slot />

  </div>
</div>
