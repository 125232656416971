<script>
  import GroupPanel from "./widgets/GroupPanel.svelte";
  import AutoComplete from "./widgets/SimpleAutocompleteInt.svelte";
  import Select from "svelte-select";

  import ModalDialog from "./widgets/ModalDialog.svelte";
  import FilterDialog from "./filter/FilterDialog.svelte";
  import { onMount } from "svelte";
  import * as api from "./api.js";
  import { loadStation, findStation } from "./manager/stations-api.js";

  import { dm1 } from "./stores.js";
  import { dmBasicWritable } from "./store-basic.js";

  import { ConfigurationTypeEnum } from "./manager/enum/configurationTypeEnum.js";

  export let loadedConfig;

  let stations;
  let showNote = true;

  const maxSelectedStationsCount = 4;

  // reset selected stations if group1stationId changes from outside
  let selectedStations = new Array(1);
  dm1.subscribe(updatedDm => {
    if (!updatedDm.group1stationId) {
      selectedStations = new Array(1);
    }
  });

  $: multipleStationsFlag = selectedStations.length > 1;

  function serializeSelectedStations() {
    const resultSet = new Set();
    if (selectedStations && selectedStations.length > 0) {
      for (let i = 0; i < selectedStations.length; i++) {
        const selectedStation = selectedStations[i];
        if (selectedStation && selectedStation.referenceId) {
          resultSet.add(selectedStation.referenceId);
        }
      }
    }
    const result = Array.from(resultSet).join(",");
    return result;
  }

  $: if (
    selectedStations &&
    selectedStations[0] &&
    selectedStations[0].referenceId
  ) {
    console.log(
      "Updating group1stationId and group1stationName from selectedStations"
    );
    const stationIdsJoined = serializeSelectedStations();
    if ($dm1.group1stationId !== stationIdsJoined) {
      $dm1.group1stationId = stationIdsJoined;
      $dm1.group1stationName = selectedStations[0].displayName;
    }
  }

  function findSelectedStationFromStationList() {
    const group1StationId = $dm1.group1stationId;
    console.log("findSelectedStationFromStationList " + group1StationId);
    if (group1StationId) {
      const group1StationIds = group1StationId.toString().split(",");
      for (let i = 0; i < group1StationIds.length; i++) {
        const stationId = group1StationIds[i];
        const foundStation = findStation(stations, stationId);
        selectedStations[i] = foundStation;
      }
    }

    const firstStation = selectedStations[0];
    if (firstStation && firstStation.referenceId) {
      if ($dm1.group1stationName != firstStation.displayName) {
        $dm1.group1stationName = firstStation.displayName;
      }
    }
  }

  $: if ($dm1.group1stationId && selectedStations[0] === undefined) {
    findSelectedStationFromStationList();
  }

  function onAddStationClick() {
    if (selectedStations.length < maxSelectedStationsCount) {
      selectedStations.push(undefined);
      selectedStations = selectedStations;
    }
  }

  function onRemoveStationClick(index) {
    // prevent removing the first station
    if (index == 0) {
      return;
    }

    selectedStations.splice(index, 1);
    selectedStations = selectedStations;
  }

  function enableDisableMultipleStations(enable) {
    if (enable) {
      onAddStationClick();
    } else {
      selectedStations = [selectedStations[0]];
    }
  }

  async function loadStationInternal(stationsUrl, apiRootUrl, network) {
    stations = undefined;
    const group1StationId = $dm1.group1stationId;
    console.log("Loading stations for", group1StationId);
    if (!group1StationId) {
      console.log("No station selected yet, aborting loading");
      return;
    }

    stations = await loadStation(
      stationsUrl,
      apiRootUrl,
      network,
      group1StationId
    );

    findSelectedStationFromStationList();
    console.log("Stations loaded, selectedStation ", selectedStations);
  }

  let stationsUrl;
  let apiRootUrl;
  let network;

  dmBasicWritable.subscribe(updatedDmBasic => {
    stationsUrl = updatedDmBasic.stationsUrl;
    apiRootUrl = updatedDmBasic.apiRootUrl;
    network = updatedDmBasic.network;
  });

  $: $dm1.group1stationId,
    loadStationInternal(stationsUrl, apiRootUrl, network);

  const minNumberOfDepartures = $dmBasicWritable.minNumberOfDepartures;
  const maxNumberOfDepartures = $dmBasicWritable.maxNumberOfDepartures;
  const departuesSize = maxNumberOfDepartures - minNumberOfDepartures + 1;

  const numberOfDepartures = new Array(departuesSize);
  for (let i = 0; i < departuesSize; i++) {
    numberOfDepartures[i] = minNumberOfDepartures + i;
  }

  function getStationSearchKeywords(station) {
    return station.referenceId + " " + station.displayName;
  }

  let filterDialogOpen = false;

  function onShowCustomStationNameClick(e) {
    let checked = e.target.checked;
    console.log("Checked: " + checked);
    if (!checked) {
      if ($dm1.group1title !== "") {
        $dm1.group1title = "";
        $dm1.uiShowCustomStationName = false;
      }
    }
  }

  // stations autocomplete
  const optionIdentifier = "referenceId";
  const getOptionLabel = option => (option ? option.displayName : "");
  const getSelectionLabel = option => (option ? option.displayName : "");

  const loadOptions = function(filterText) {
    filterText = filterText ? filterText.replace(" ", "%20") : "";

    return new Promise((resolve, reject) => {
      if (!stationsUrl) {
        reject();
      }
      let url = stationsUrl.supplant({
        apiRootUrl: apiRootUrl,
        network: network
      });

      const limit = 15;
      url = `${url}?limit=${limit}&name=${filterText}`;

      const stationsPromise = api.getWithOptionalJsonP(url);
      stationsPromise.then(response => {
        const stationsJson2 = response.stations;
        resolve(stationsJson2);
      });

      stationsPromise.catch(error => {
        reject();
      });
    });
  };
</script>

<style>
  .is-marginless-bottom {
    margin-bottom: 0em !important;
  }
  .margin-bottom {
    margin-bottom: 0.5em;
  }
  .nested {
    margin-left: 1rem;
  }
</style>

<GroupPanel
  title={loadedConfig && loadedConfig.type === ConfigurationTypeEnum.TEMPLATE ? 'Preview station' : 'Station'}>
  {#if loadedConfig && loadedConfig.type === ConfigurationTypeEnum.TEMPLATE && showNote}
    <div class="notification is-warning">
      <button class="delete" on:click={() => (showNote = false)} />
      Note: Selecting a station and number of departures in a template is just
      for preview and does not affect screen configurations based on it
    </div>
  {/if}

  {#each selectedStations as selectedStation, i}
    <div class="field is-marginless-bottom" class:nested={i > 0}>
      <label class="label is-small margin-bottom">
        {loadedConfig && loadedConfig.type === ConfigurationTypeEnum.TEMPLATE ? 'Preview station' : 'Station'}
        {#if selectedStations.length > 1}{i + 1}{/if}
      </label>
    </div>
    <div class="field has-addons" class:nested={i > 0}>
      <div class="control is-expanded">
        <Select
          {loadOptions}
          {optionIdentifier}
          {getSelectionLabel}
          {getOptionLabel}
          on:clear={() => {
            if (i == 0) {
              $dm1.group1stationId = undefined;
            }
            selectedStations[i] = undefined;
          }}
          placeholder="Search for station"
          hideEmptyState="true"
          noOptionsMessage="No results"
          bind:selectedValue={selectedStations[i]} />
      </div>

      {#if i > 0}
        <div class="control">
          <button
            class="button is-pulled-right"
            on:click={e => onRemoveStationClick(i)}>

            <span class="icon is-small">
              <i class="fas fa-trash-alt" aria-hidden="true" />
            </span>

            <span>Remove</span>
          </button>
        </div>
      {/if}

    </div>

    {#if i === 0 && loadedConfig && loadedConfig.type === ConfigurationTypeEnum.NORMAL}
      <div class="field">
        <div class="control ">
          <input
            type="checkbox"
            checked={multipleStationsFlag}
            on:click={e => enableDisableMultipleStations(!multipleStationsFlag)}
            class="is-checkradio"
            id="multipleStations" />
          <label for="multipleStations" class="label">Multiple stations</label>
        </div>
      </div>
    {/if}
  {/each}

  {#if selectedStations.length >= 2}
    <div class="field is-clearfix nested">
      <div class="control">
        <button
          class="button"
          on:click={onAddStationClick}
          disabled={selectedStations.length >= maxSelectedStationsCount}>
          <span class="icon is-small">
            <i class="fas fa-plus" aria-hidden="true" />
          </span>
          <span>Add station</span>
        </button>
      </div>
    </div>
  {/if}

  {#if false}
    <div class="field ">
      <label class="label is-small">Debug</label>
      <div class="control">
        {#each selectedStations as selectedStation, i}
          <p>selectedStation{i + 1}: {selectedStations[i]}</p>
        {/each}
        <p>selectedStations: [{selectedStations}]</p>
        <p>$dm1.group1stationId: {$dm1.group1stationId}</p>
        <p>$dm1.group1stationName: {$dm1.group1stationName}</p>
      </div>
    </div>
  {/if}

  <div class="field">
    <label
      class="label is-small"
      for="group1numDepartures"
      title={$dm1.contentLayout === 'trainSingleDeparture' ? 'Number of departures can not be changed in the current layout' : ''}>
      Number of Departures
    </label>
    <div class="control">
      <div class="select is-fullwidth">
        <select
          id="group1numDepartures"
          disabled={$dm1.contentLayout === 'trainSingleDeparture'}
          title={$dm1.contentLayout === 'trainSingleDeparture' ? 'Number of departures can not be changed in the current layout' : ''}
          bind:value={$dm1.group1numDepartures}>
          {#each numberOfDepartures as n}
            <option value={n}>{n}</option>
          {/each}
        </select>
      </div>
    </div>
  </div>

  <div class="field">
    <div class="control">
      {#if loadedConfig && loadedConfig.uid && loadedConfig.type === 'TEMPLATE'}

      {:else}
        <a
          on:click={() => (filterDialogOpen = !filterDialogOpen)}
          disabled={loadedConfig && loadedConfig.uid && loadedConfig.type === 'TEMPLATE'}>
          Filtering by Platform / Direction
        </a>
        {#if $dm1.agencies || $dm1.lines || $dm1.platforms || $dm1.destinations || ($dm1.platformIds && $dm1.platformIds.length > 0) || ($dm1.directions && $dm1.directions.length > 0)}
          <p class="help">
            Filters applied:
            {#if $dm1.platformIds && $dm1.platformIds.length > 0}
              &#9642; platform
            {/if}
            {#if $dm1.directions && $dm1.directions.length > 0}
              &#9642; direction
            {/if}
            {#if $dm1.agencies}&#9642; agnecy{/if}
            {#if $dm1.lines}&#9642; line{/if}
            {#if $dm1.platforms}&#9642; platform text{/if}
            {#if $dm1.destinations}&#9642; destination{/if}
          </p>
        {/if}
      {/if}

    </div>
  </div>
</GroupPanel>

<ModalDialog
  id="filterDialog"
  title="Advanced Filtering"
  bind:active={filterDialogOpen}>
  <FilterDialog />
</ModalDialog>
