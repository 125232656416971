import { ErrorTypeEnum } from "../manager/enum/errorTypeEnum.js";
export function logError(message) {
  console.error(message);
  alert(message);
}

export class EntityNotFoundError extends Error {
  constructor(message) {
    super(message);
    this.errType = ErrorTypeEnum.NOT_FOUND;
  }
}

export class IllegalArgumentException extends Error {
  constructor(message) {
    super(message);
    this.errType = ErrorTypeEnum.BAD_REQUEST_PARAMETERS;
  }
}

export class EntityNotCreatedError extends Error {
  constructor(message) {
    super(message);
    this.errType = ErrorTypeEnum.NOT_CREATED;
  }
}

export class EntityNotModifiedError extends Error {
  constructor(message) {
    super(message);
    this.errType = ErrorTypeEnum.NOT_MODIFIED;
  }
}

export class UniqueEntityAttributeError extends Error {
  constructor(message) {
    super(message);
  }
}

export class ForbbidenServiceError extends Error {
  constructor(message, reason) {
    super(message);
    this.errType = ErrorTypeEnum.FORBIDDEN;
    this.reason = reason;
  }
}
