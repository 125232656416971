export class Utilities {
  static mergeShallowRight(state, customObject) {
    if (typeof state !== "object" || typeof customObject !== "object") {
      return new TypeError("Both arguments must be of type object");
    }

    let result = {};
    result = Object.assign(result, state);
    result = Object.assign(result, customObject);
    return result;
  }

  static objectKeysToArray(inputObject) {
    return this.sanitizeArray(Object.keys(inputObject));
  }

  static sanitizeArray(inputArray) {
    return inputArray.filter(el => el !== null && el !== undefined);
  }

  static isObjectEmpty(customObject) {
    let isEmpty = true;
    for (let key in customObject) {
      isEmpty = false;
      break;
    }

    return isEmpty;
  }

  static deepDiff(firstObject, secondObject) {
    let buildingObject = {};
    for (let [key, value] of Object.entries(secondObject)) {
      if (secondObject.hasOwnProperty(key)) {
        if (
          typeof firstObject[key] === "object" &&
          !Array.isArray(firstObject)
        ) {
          const diff = this.deepDiff(firstObject[key], secondObject[key]);

          if (!this.isObjectEmpty(diff)) {
            let subBuildingObject = {
              [key]: this.mergeShallowRight({}, diff)
            };
            buildingObject = this.mergeShallowRight(
              buildingObject,
              subBuildingObject
            );
          }
        } else {
          if (firstObject[key] !== secondObject[key]) {
            buildingObject = this.mergeShallowRight(buildingObject, {
              [key]: value
            });
          }
        }
      }
    }
    return buildingObject;
  }

  static mergeDeepRight(parentObject, childObject) {
    const buildingObject = this.deepCopy(parentObject);

    for (let [key, value] of Object.entries(childObject)) {
      if (buildingObject.hasOwnProperty(key)) {
        if (
          typeof childObject[key] === "object" &&
          !Array.isArray(childObject[key])
        ) {
          buildingObject[key] = this.mergeDeepRight(
            buildingObject[key],
            childObject[key]
          );
        } else {
          buildingObject[key] = value;
        }
      } else {
        buildingObject[key] = value;
      }
    }

    return buildingObject;
  }

  static deepCopy(inputObject) {
    let objectCopy = {};
    for (let [key, value] of Object.entries(inputObject)) {
      if (
        typeof inputObject[key] === "object" &&
        !Array.isArray(inputObject[key])
      ) {
        objectCopy = this.mergeShallowRight(objectCopy, {
          [key]: this.deepCopy(inputObject[key])
        });
      } else {
        objectCopy = this.mergeShallowRight(objectCopy, {
          [key]: value
        });
      }
    }
    return objectCopy;
  }

  static createArrayWithNFields(n) {
    return new Array(n);
  }
}

export class Features {
  static changeSort() {
    const sort = {
      sortOrder: "desc",
      sortField: "id",

      toString: function () {
        return `${this.sortField},${this.sortOrder}`;
      }
    };
    return function (field) {
      if (field !== sort.sortField) {
        sort.sortField = field;
        sort.sortOrder = "asc";
      } else {
        // field === sort.sortField
        if (sort.sortOrder === "asc") {
          sort.sortOrder = "desc";
        } else {
          sort.sortOrder = "asc";
        }
      }
      return sort.toString();
    };
  }
}
