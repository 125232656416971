<script>
  import { sendAuthenticateRequest, getUser } from "./login-api.js";
  import { onMount } from "svelte";
  import { login } from "../store-user.js";

  let username = "";
  let password = "";

  let usernameBad = false;
  let passwordBad = false;
  let loginFailed = false;
  let usernameControl;

  onMount(async () => {
    usernameControl.focus();
  });

  export async function attemptLogin() {
    console.log("Username: " + username);
    // validate username and password
    if (username.trim().length <= 0) {
      usernameBad = true;
      return false;
    } else {
      usernameBad = false;
    }
    if (password.trim().length <= 0) {
      passwordBad = true;
      return false;
    } else {
      passwordBad = false;
    }

    const authenticateResponse = await sendAuthenticateRequest(
      username,
      password
    );
    console.log(
      "authenticateResponse: " + JSON.stringify(authenticateResponse)
    );

    let success = false;
    if (authenticateResponse && authenticateResponse.id_token) {
      const authToken = authenticateResponse.id_token;
      console.log("Token: " + authToken);

      const user = await getUser(username, authToken);
      if (user && user.id && user.login) {
        login(user, authToken);
        success = true;
      }
    }

    if (!success) {
      loginFailed = true;
    }

    return success;
  }
</script>

<div class="content">
  <p>
    Please enter your
    <strong>Wemlin Hub</strong>
    credentials.
  </p>
</div>

<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">Username</label>
  </div>
  <div class="field-body">
    <div class="field">
      <p class="control is-expanded">
        <input
          id="username"
          name="username"
          class="input"
          type="text"
          bind:this={usernameControl}
          placeholder="Username"
          bind:value={username} />
      </p>

      {#if usernameBad}
        <p class="help is-danger">Please enter username</p>
      {/if}
    </div>
  </div>
</div>

<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label">Password</label>
  </div>
  <div class="field-body">
    <div class="field">
      <p class="control is-expanded">
        <input
          id="password"
          name="password"
          class="input"
          type="password"
          placeholder="Password"
          bind:value={password} />
      </p>
      {#if passwordBad}
        <p class="help is-danger">Please enter password</p>
      {/if}
    </div>
  </div>
</div>

{#if loginFailed}
  <div class="field">
    <p class="help is-danger">Login failed, username or password not correct</p>
  </div>
{/if}
