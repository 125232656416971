<script>
  import { dm1 } from "../stores.js";
  import { dmBasic } from "../store-basic.js";
  import { onMount } from "svelte";
  import * as api from "../api.js";

  const station = $dm1.group1stationId;

  let directionsList;
  const uniqueDirectionsPerAgency = {};

  $: allDirections = $dm1.directions.trim() === "";

  onMount(async () => {
    if (station) {
      const url = $dmBasic.directionsUrl.supplant({
        apiRootUrl: $dmBasic.apiRootUrl,
        network: $dmBasic.network,
        stationId: station
      });
      const directionsJson = await api.getWithOptionalJsonP(url);
      console.log("JSON: " + directionsJson);
      directionsList = directionsJson;

      if (directionsList) {
        directionsList.directions.forEach(direction => {
          const agency = direction.agency;

          uniqueDirectionsPerAgency[agency] = new Set();
          uniqueDirectionsPerAgency[agency].add("*");

          direction.lines.forEach(line => {
            line.directions.forEach(simpleDirection => {
              const directionId = simpleDirection.directionId;

              if (uniqueDirectionsPerAgency[agency] === undefined) {
                uniqueDirectionsPerAgency[agency] = new Set();
              }

              const set = uniqueDirectionsPerAgency[agency];
              set.add(directionId);

              for (const item of set.values()) console.log("item: " + item);
            });
          });
        });
      }
    }
  });

  const getLineColors = function(line) {
    const result = {};

    if (line && line.colors) {
      // Check for text color
      if (line.colors.fg) {
        result["color"] = "rgb(" + line.colors.fg.join() + ")";
      }
      // Check for background color
      if (line.colors.bg) {
        result["background-color"] = "rgb(" + line.colors.bg.join() + ")";
        if (line.colors.bg == [255, 255, 255]) {
          result["border"] = "1px solid #000";
        }
      }
    } else {
      result["border"] = "1px solid #000";
    }

    result["padding"] = "2px 10px";

    return Object.entries(result).reduce(
      (styleString, [propName, propValue]) => {
        return `${styleString}${propName}:${propValue};`;
      },
      ""
    );
  };

  function addDirection(e, directionId) {
    const target = e.target;
    const checked = target.checked;

    let dir = $dm1.directions;

    dir = removeCommas(dir);

    dir = dir.replace(directionId, "");

    dir = dir.replace(/\s/g, "");

    while (dir.includes(",,")) {
      dir = dir.replace(",,", ",");
    }

    dir = dir.trim();

    if (checked) {
      dir += "," + directionId;
    }

    dir = removeCommas(dir);

    $dm1.directions = dir;
  }

  function removeCommas(dir) {
    dir = dir.trim();
    if (dir.startsWith(",")) {
      dir = dir.substring(1);
    }
    if (dir.endsWith(",")) {
      dir = dir.substring(0, dir.length - 1);
    }

    dir = dir.trim();
    return dir;
  }

  function onAllDirectionsClick(e) {
    if (e.target.checked) {
      $dm1.directions = "";
    }
  }
</script>

<style>
  .field > label {
    width: 200px;
    line-height: 2;
  }
  .w80p {
    width: 80%;
  }
</style>

<div class="field is-grouped">
  <label class="label">Direction IDs</label>
  <div class="control w80p">
    <input
      class="input "
      type="text"
      placeholder="Comma separated Angency:DirectionID to show"
      bind:value={$dm1.directions} />
  </div>
</div>

<div class="field">
  <div class="control">
    <label class="checkbox">
      <input
        type="checkbox"
        bind:checked={allDirections}
        on:click={onAllDirectionsClick} />
      All Directions
    </label>
  </div>
</div>

{#if directionsList}
  <div id="uniqueDirectionsList" style="margin-bottom: 1rem">
    {#each Object.entries(uniqueDirectionsPerAgency) as uniqueDirectionPerAgency}
      <div class="field is-grouped">
        {#each [...uniqueDirectionPerAgency[1]] as directionId}
          <div class="control">
            <label class="label">
              <input
                type="checkbox"
                class="checkbox"
                checked={$dm1.directions.includes(uniqueDirectionPerAgency[0] + ':' + directionId)}
                on:click={e => addDirection(e, uniqueDirectionPerAgency[0] + ':' + directionId)} />
              {uniqueDirectionPerAgency[0]}:{directionId}
            </label>
          </div>
        {/each}
      </div>
    {/each}
  </div>

  <div id="directionsList">
    {#each directionsList.directions as direction}
      <div>
        {#each direction.lines as line}
          <div>
            {direction.agency}
            <span style={getLineColors(line.line)}>{line.line.line_name}</span>
            {#each line.directions as simpleDirection}
              <div>
                {simpleDirection.directionId} - {simpleDirection.endStationNamesAndDirectionTextsString}
              </div>
            {/each}
          </div>
        {/each}
      </div>
    {/each}
  </div>
{/if}
