<script>
  import GroupPanel from "./widgets/GroupPanel.svelte";
  import ColorSelector from "./widgets/ColorSelector.svelte";
  import FontSelector from "./widgets/FontSelector.svelte";

  import { dm1 } from "./stores.js";
  import { userLoggedInStore } from "./store-user.js";
</script>

<style>
  .formContainer {
    margin-right: 16px;
  }
</style>

<GroupPanel title="Main Styles">
  <div class="formContainer">
    <div class="field">
      <label class="label">Font</label>
    </div>
    <div class="field is-grouped">
      <FontSelector icon="font" className="w66" bind:selected={$dm1.font} />
    </div>

    <div class="field">
      <label class="label">Basic Colors</label>
    </div>

    <div class="field is-grouped">
      <ColorSelector
        showInherit="true"
        bind:selected={$dm1.bgColor}
        defaultColor={$dm1.defaultBgColor}
        className="wemlinOneThirdWidth" />
      <ColorSelector
        showInherit="true"
        bind:selected={$dm1.fgColor}
        defaultColor={$dm1.defaultFgColor}
        icon="font"
        className="wemlinOneThirdWidth" />
    </div>
    {#if $dm1.contentLayout !== 'trainSingleDeparture'}
      <div class="field">
        <label class="label">Header Colors</label>
      </div>

      <div class="field is-grouped">
        <ColorSelector
          showInherit="true"
          bind:selected={$dm1.headerBgColor}
          defaultColor={$dm1.bgColor ? $dm1.bgColor : $dm1.defaultBgColor}
          className="wemlinOneThirdWidth" />

        <ColorSelector
          showInherit="true"
          bind:selected={$dm1.headerFgColor}
          icon="font"
          defaultColor={$dm1.fgColor ? $dm1.fgColor : $dm1.defaultFgColor}
          className="wemlinOneThirdWidth" />
      </div>

      <div class="field">
        <label class="label">Footer Colors</label>
      </div>

      <div class="field is-grouped">
        <ColorSelector
          showInherit="true"
          bind:selected={$dm1.footerBgColor}
          defaultColor={$dm1.bgColor ? $dm1.bgColor : $dm1.defaultBgColor}
          className="wemlinOneThirdWidth" />

        <ColorSelector
          showInherit="true"
          bind:selected={$dm1.footerFgColor}
          icon="font"
          defaultColor={$dm1.fgColor ? $dm1.fgColor : $dm1.defaultFgColor}
          className="wemlinOneThirdWidth" />

      </div>
    {:else}
      <p class="help">
        Header and footer are not supported in the current layout.
      </p>
    {/if}

  </div>
</GroupPanel>

{#if $userLoggedInStore}
  <GroupPanel title="Disruption Message Style" hidden>
    <div class="formContainer">

      {#if $dm1.contentLayout !== 'trainSingleDeparture'}
        <div class="field">
          <label class="label">Animated Service Alerts Colors</label>
        </div>

        <div class="field is-grouped">
          <ColorSelector
            showInherit="true"
            bind:selected={$dm1.marqueeBgColor}
            defaultColor={$dm1.defaultMarqueeBgColor}
            className="wemlinOneThirdWidth" />

          <ColorSelector
            showInherit="true"
            bind:selected={$dm1.marqueeFgColor}
            icon="font"
            defaultColor={$dm1.defaultMarqueeFgColor}
            className="wemlinOneThirdWidth" />

        </div>

        <div class="field">
          <label class="label">Static Service Alerts Colors</label>
        </div>

        <div class="field is-grouped">
          <ColorSelector
            showInherit="true"
            bind:selected={$dm1.serviceAlertBgColor}
            defaultColor={$dm1.defaultServiceAlertBgColor}
            className="wemlinOneThirdWidth" />

          <ColorSelector
            showInherit="true"
            bind:selected={$dm1.serviceAlertFgColor}
            icon="font"
            defaultColor={$dm1.serviceAlertFgColor}
            className="wemlinOneThirdWidth" />

        </div>
      {:else}
        <p class="help">
          Disruption Messages are not supported in the current layout.
        </p>
      {/if}
    </div>
  </GroupPanel>
{/if}

<GroupPanel title="Departures Table Style" hidden>
  <div class="formContainer">
    {#if $dm1.contentLayout !== 'trainSingleDeparture'}
      <div class="field">
        <label class="label">Splitter Lines Color</label>
      </div>

      <div class="field is-grouped">
        <ColorSelector
          showInherit="true"
          bind:selected={$dm1.splitLineColor}
          defaultColor={$dm1.defaultSplitLineColor}
          className="wemlinOneThirdWidth" />
      </div>
      {#if $userLoggedInStore}
        <div class="field">
          <label class="label">Late Departures Text Color</label>
        </div>

        <div class="field">

          <ColorSelector
            showInherit="true"
            bind:selected={$dm1.lateColor}
            icon="font"
            defaultColor={$dm1.defaultLateColor}
            className="wemlinOneThirdWidth" />

        </div>
      {/if}

      <div class="field">
        <label class="label">Icons Style</label>
      </div>

      <div class="field">

        <div class="select">
          <select bind:value={$dm1.iconStyle}>
            <option value="">Default</option>
            <option value="white">White</option>
            <option value="light_gray">Light Gray</option>
            <option value="dark_gray">Dark Gray</option>
            <option value="black">Black</option>
          </select>
        </div>

      </div>
    {:else}
      <p class="help">
        Departure Table is not supported in the current layout.
      </p>
    {/if}
  </div>
</GroupPanel>
