<script>
  // stores
  import { dm1 } from "../stores.js";

  // core
  import { createEventDispatcher, onMount } from "svelte";

  // utils
  import { Utilities } from "../utils.js";

  // components
  import ColumnsTableRow from "./ColumnsTableRow.svelte";

  // input properties
  export let columns = [];
  export let selectedTabIndex;
  export let columnConfig = undefined;

  // dispatcher
  const dispatch = createEventDispatcher();

  function moveDown(event) {
    const { pos } = event.detail;

    const index = columns.findIndex(el => el.pos === pos);
    if (pos < columns.length && index !== -1) {
      const temp = columns[index];
      columns[index] = columns[index + 1];
      columns[index + 1] = temp;

      columns[index].pos = index + 1;
      columns[index + 1].pos = index + 2;

      columnConfig[columns[index].configName].pos = index + 1;
      columnConfig[columns[index + 1].configName].pos = index + 2;
    }
  }

  function moveUp(event) {
    const { pos } = event.detail;

    const index = columns.findIndex(el => el.pos === pos);

    if (pos > 1 && index !== -1) {
      const temp = columns[index];
      columns[index] = columns[index - 1];
      columns[index - 1] = temp;

      columns[index].pos = index + 1;
      columns[index - 1].pos = index;

      columnConfig[columns[index].configName].pos = index + 1;
      columnConfig[columns[index - 1].configName].pos = index;
    }
  }
</script>

<table class="table is-hoverable is-fullwidth">
  <thead>
    <tr>
      <th>
        <abbr title="Position">#</abbr>
      </th>
      <th>Column</th>
      <th>Show</th>
      <th>Move</th>
    </tr>
  </thead>
  <tbody>
    {#each columns as column, i}
      <ColumnsTableRow
        bind:show={columnConfig[column.configName].show}
        bind:pos={columnConfig[column.configName].pos}
        {selectedTabIndex}
        name={column.name}
        label={column.label}
        disabled={column.disabled}
        on:moveDown={moveDown}
        on:moveUp={moveUp} />
    {/each}
  </tbody>
</table>
