import * as basicHttpClient from "../http-client.js";
import * as httpClient from "../api.js";
import { dmBasicInitial } from "../store-basic.js";

const autheticateApiUrl = dmBasicInitial.managmentApiRootUrl + "authenticate";

const usersApiUrl = dmBasicInitial.managmentApiRootUrl + "users";


export async function sendAuthenticateRequest(username, password) {
  const requestUrl = autheticateApiUrl;
  console.log("Login with username: ", username);
  const postBody = {
    username: username,
    password: password,
    rememberMe: "true"
  };
  return basicHttpClient.send({ method: 'POST', url: requestUrl, data: postBody });
}


export async function getUser(username, authToken) {
  const authTokenWithPrefix = "Bearer " + authToken;
  const requestUrl = `${usersApiUrl}/${username}`;
  console.log("Getting user details for user: ", username, " token: ", authTokenWithPrefix);
  return httpClient.getWithToken(requestUrl,  authTokenWithPrefix);
}
