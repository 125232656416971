<script>
  import LoginForm from "./LoginForm.svelte";
  import ModalDialog from "../widgets/ModalDialog.svelte";
  let showLogin = false;
  let loginFormComponent;

  export function showLoginForm() {
    console.log("showLoginForm()");
    showLogin = true;
  }

  async function handleLoginFromModalClick() {
    const loginSuccess = await loginFormComponent.attemptLogin();
    console.log("Navbar login result: " + loginSuccess);
    return loginSuccess;
  }

  $: console.log("showLoginForm: " + showLogin);
</script>

{#if showLogin}
  <ModalDialog
    title="Log in"
    okHandler={handleLoginFromModalClick}
    bind:active={showLogin}
    okText="Log in"
    showCancel="true"
    width="450">
    <LoginForm bind:this={loginFormComponent} />
  </ModalDialog>
{/if}
