<script>
  import { onMount } from "svelte";

  export let id = "";
  export let title = "";
  export let active = false;
  export let showCancel = false;
  export let okText = "OK";
  export let cancelText = "Cancel";
  export let okDanger = false;
  export let okHandler = false;
  export let cancelHandler = false;
  export let handleEnter = true;

  export let width = undefined;

  onMount(() => {});

  async function handleClickOk() {
    if (okHandler) {
      const isAsync = okHandler.constructor.name === "AsyncFunction";

      if (isAsync) {
        if (await okHandler()) {
          hideDialog();
        }
      } else {
        if (okHandler()) {
          hideDialog();
        }
      }
    } else {
      hideDialog();
    }
  }

  function handleClickCancel() {
    if (cancelHandler) {
      if (cancelHandler()) {
        hideDialog();
      }
    } else {
      hideDialog();
    }
  }

  function hideDialog() {
    active = false;
  }

  function handleKeydown(event) {
    if (!active) {
      return;
    }
    const key = event.key;
    const keyCode = event.keyCode;
    // console.log("key: " + key + ", code: " + keyCode);
    if (handleEnter && keyCode === 13) {
      // enter
      handleClickOk();
    } else if (keyCode === 27) {
      // escape
      handleClickCancel();
    }
  }
</script>

<svelte:window on:keydown={handleKeydown} />

<div {id} class="modal" class:is-active={active}>
  <div class="modal-background" />
  <div class="modal-card" style={width ? 'width: ' + width + 'px;' : ''}>
    {#if title}
      <header class="modal-card-head">
        <p class="modal-card-title">{title}</p>
        <button
          class="delete"
          aria-label="close"
          on:click={handleClickCancel} />
      </header>
    {/if}
    <section class="modal-card-body" class:headless={!title}>
      {#if active}
        <slot />
      {/if}
    </section>
    <footer class="modal-card-foot">

      <button
        class="button"
        class:is-primary={showCancel && !okDanger}
        class:is-danger={showCancel && okDanger}
        on:click={handleClickOk}
        style="min-width: 120px">
        {okText}
      </button>

      {#if showCancel}
        <button
          class="button"
          on:click={handleClickCancel}
          style="min-width: 120px">
          {cancelText}
        </button>
      {/if}
    </footer>
  </div>
</div>
