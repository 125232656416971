export function base64Encoder(jsonObject) {
  const value = JSON.stringify(jsonObject);

  const base64EncodedValue = btoa(value);
  return base64EncodedValue;
}

export function base64Decoder(inputString) {
  const base64DecodedValue = atob(inputString);

  const value = JSON.parse(base64DecodedValue);

  return value;
}
