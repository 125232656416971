export const ConfigurationTypeEnum = Object.freeze({
  NORMAL: "NORMAL",
  TEMPLATE: "TEMPLATE"
});

export function ConfigurationTypeEnumLabel(configurationTypeEnum) {
  switch (configurationTypeEnum) {
    case ConfigurationTypeEnum.NORMAL: return "Normal";
    case ConfigurationTypeEnum.TEMPLATE: return "Template";
    default:
      return "Unknown Configuration Type";
  }
}