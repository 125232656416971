<script>
  import { createEventDispatcher } from "svelte";

  // components
  import ModalDialog from "./widgets/ModalDialog.svelte";

  import { EntityNotFoundError } from "./util/error-utils.js";

  // functions
  import { updateConfig } from "./config-save-load.js";
  import { saveSegmentedScreen } from "./segmented-screen/service/segmented-screen-api.js";
  import { UniqueEntityAttributeError } from "./util/error-utils";

  // input properties
  export let loadedConfig;

  let stagedConfigurationTitle;

  $: loadedConfigName =
    loadedConfig && loadedConfig.entity
      ? loadedConfig.entity.name
      : loadedConfig.name;

  const dispatch = createEventDispatcher();

  // show modals
  let showRenameConfigurationModal = false;

  // Rename
  function openRenameConfigurationModal() {
    if (loadedConfig) {
      if (loadedConfig.uid || (loadedConfig.entity && loadedConfig.entity.id)) {
        stagedConfigurationTitle = loadedConfigName;
        showRenameConfigurationModal = true;
      }
    }
  }

  async function handleRenameConfiguration() {
    // do not allow empty configuration name
    if (!stagedConfigurationTitle || stagedConfigurationTitle.trim() === "") {
      return;
    }
    try {
      let updatedConfiguration;

      if (loadedConfig.uid) {
        updatedConfiguration = await updateConfig(loadedConfig.uid, {
          name: stagedConfigurationTitle
        });
        loadedConfig.name = updatedConfiguration.name;
      } else if (loadedConfig.entity && loadedConfig.entity.id) {


        updatedConfiguration = await saveSegmentedScreen({
          id: loadedConfig.entity.id,
          name: stagedConfigurationTitle
        });
        loadedConfig.entity.name = updatedConfiguration.name;
      }

      showRenameConfigurationModal = false;
    } catch (err) {
      showRenameConfigurationModal = false;
      let errMsg;
      if (err instanceof EntityNotFoundError) {
        errMsg = "Could not save configuration, not found. Please try again.";
      } else if (err instanceof UniqueEntityAttributeError) {
        errMsg = err.message;
      } else {
        errMsg = "Could not save configuration. Please try again.";
      }

      dispatch("showNotification", {
        success: false,
        text: errMsg
      });
    }
  }

  function handleCancelRenameConfiguration() {
    stagedConfigurationTitle = undefined;
    showRenameConfigurationModal = false;
  }
</script>

<style>
  .configurations-names {
    display: inline-flex;
  }

  .configurations-names {
    flex-direction: column;
    align-items: flex-start;
  }

  .configurations-names .navbar-item {
    padding: 0.3rem 0.75rem;
  }

  .configurations-names .navbar-link {
    padding: 0.2rem 0.5rem !important;
    padding-left: 0.1rem !important;
    line-height: 1;
  }

  .configurations-names .navbar-link:hover {
    background-color: #5b78a5;
    cursor: pointer;
  }

  .configurations-names .title {
    margin-left: 0;
  }

  .template-name {
    font-weight: initial;
    font-size: 0.8rem;
  }
</style>

<div class="configurations-names navbar-item">
  {#if loadedConfig}
    <div
      class="navbar-item"
      class:is-hoverable={loadedConfig.uid || (loadedConfig.entitiy && loadedConfig.entitiy.id)}
      title={loadedConfig.uid ? loadedConfigName + ' - Rename' : ''}>

      <div
        class="navbar-link is-arrowless title is-6"
        on:click={openRenameConfigurationModal}>
        <span class="icon is-medium" style="font-size: 1.4rem;">
          <i class="fas fa-desktop" />
        </span>
        &nbsp;
        <span>
          {loadedConfigName}
          <br />
          {#if loadedConfig.parentConfiguration}
            <span class="template-name">
              {loadedConfig.parentConfiguration.name}
            </span>
          {/if}
        </span>
      </div>
    </div>
  {/if}
</div>

{#if loadedConfig}
  <ModalDialog
    title="Rename configuration {loadedConfigName}"
    bind:active={showRenameConfigurationModal}
    okText="Rename"
    showCancel="true"
    cancelHandler={handleCancelRenameConfiguration}
    okHandler={handleRenameConfiguration}>
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label">Name</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input
              class="input"
              type="text"
              placeholder=""
              bind:value={stagedConfigurationTitle} />
          </div>
        </div>
      </div>
    </div>
  </ModalDialog>
{/if}
