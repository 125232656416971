<script>
  import { createEventDispatcher } from "svelte";

  // components
  import UpDownSpinner from "../widgets/UpDownSpinner.svelte";
  import ColumnLabel from "./ColumnLabel.svelte";

  // utils
  import { AlignmentTypeEnum } from "../manager/enum/alignmentTypeEnum.js";

  // input properties
  export let pos = undefined;
  export let configName = undefined;
  export let name = undefined;
  export let label = undefined;
  export let selectedTabIndex = undefined;
  export let width = undefined;
  export let alignment = undefined;
  export let paddingLeft = undefined;
  export let paddingRight = undefined;
  export let show = true;
  export let disabled = undefined;

  // model
  let doModifyLabel = false;

  const dispatch = createEventDispatcher();

  $: alignment, paddingLeft, paddingRight, dispatchSpacing();

  function handleLabelSave(event) {
    const { label } = event.detail;
    const payload = {
      configName,
      label
    };

    dispatch("labelChange", {
      payload
    });

    doModifyLabel = false;
  }

  function dispatchSpacing() {
    const payload = {
      configName,
      alignment,
      paddingLeft,
      paddingRight
    };
    dispatch("spacingChange", {
      payload
    });
  }

  const editColumn = false;

  function moveDown() {
    dispatch("moveDown", {
      pos
    });
  }

  function moveUp() {
    dispatch("moveUp", {
      pos
    });
  }
</script>

<style>
  .lineRow {
    line-height: 2.3rem;
  }

  .noWrap {
    white-space: nowrap;
  }
</style>

<tr class="lineRow">
  <th>{pos}</th>
  <td class="noWrap">
    <strong>{name}</strong>
  </td>
  {#if selectedTabIndex === 2}
    <td class="noWrap">
      {#if doModifyLabel}
        <ColumnLabel
          {label}
          on:saveColumnLabel={handleLabelSave}
          on:discardColumnLabelChanges={() => {
            doModifyLabel = false;
          }} />
      {:else}
        <button
          type="button"
          class="button is-primary"
          on:click={() => (doModifyLabel = true)}>
          Modify
        </button>
      {/if}

    </td>
    <td class="noWrap">
      <div class="control">
        <div class="select">
          <select bind:value={alignment}>
            {#each Object.keys(AlignmentTypeEnum) as type, i}
              <option value={type.toLowerCase()}>
                {AlignmentTypeEnum[type]}
              </option>
            {/each}
          </select>
        </div>
      </div>
    </td>
    <td class="noWrap">
      <div class="control">
        <UpDownSpinner floatStep="0.1" bind:value={paddingLeft} />
        <!-- <input type="number" min="0" step="0.1" bind:value={paddingLeft} /> -->
      </div>
    </td>
    <td class="noWrap">
      <div class="control">
        <UpDownSpinner floatStep="0.1" bind:value={paddingRight} />
        <!-- <input type="number" min="0" step="0.1" bind:value={paddingRight} /> -->
      </div>
    </td>
  {/if}
  {#if selectedTabIndex === 1}
    <td class="nowWrap">
      <div class="control">
        <p>{width}%</p>
      </div>
    </td>
  {/if}
  {#if selectedTabIndex === 0}
    <td class="noWrap">
      <label class="checkbox" style="text-align: center;">
        <input type="checkbox" bind:checked={show} {disabled} />
      </label>
    </td>
    <td class="noWrap">
      <a on:click={moveUp}>
        <span class="icon is-small">
          <i class="fas fa-arrow-up" />
        </span>
      </a>
      <a on:click={moveDown}>
        <span class="icon is-small">
          <i class="fas fa-arrow-down" />
        </span>
      </a>
    </td>
  {/if}
</tr>
