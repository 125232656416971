<script>
  import { onMount } from "svelte";
  import { getLatestSegmentedScreens } from "./segmented-screen/service/segmented-screen-api";

  export let loadedConfig;

  let segmentedScreens = [];

  const segmentedScreenConfigurationsLink = "manager.html#/segmented-screens";

  onMount(async () => {
    await loadSegmentedScreens();
  });

  async function loadSegmentedScreens() {
    segmentedScreens = await getLatestSegmentedScreens();
  }
</script>

<div class="navbar-item has-dropdown is-hoverable">
  <a class="navbar-link">
    <span class="icon is-small">
      <i class="fas fa-folder" />
    </span>
    My Configurations
  </a>

  <div class="navbar-dropdown truncate-text" style="max-width: 280px;">
    <a href={segmentedScreenConfigurationsLink} class="navbar-item">
      All Segmented Screens
    </a>
    {#if segmentedScreens && segmentedScreens.length > 0}
      <hr class="navbar-divider" />
      <div class="help">Recent Configurations:</div>
      {#each segmentedScreens as segmentedScreen}
        <a
          href="segmented-screen-config.html?c={segmentedScreen.uid}"
          class="navbar-item"
          class:has-text-weight-bold={loadedConfig && loadedConfig.entity.id === segmentedScreen.uid}>
          {segmentedScreen.name}
        </a>
      {/each}
    {/if}
  </div>
</div>
