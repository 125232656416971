import { writable, readable } from "svelte/store";

const basicColumnConfig = {
  uiColumnConfigPreset: {
    columnConfigName: "Normal preset",
    columnConfigId: "",
    supportedLayouts: ["oneColumn", "twoColumns"]
  },
  vehicleType: {
    pos: 1,
    name: "Vehicle Icon",
    label: {},
    show: true,
    width: 7,
    align: "center",
    pL: 0,
    pR: 1,
    disabled: false
  },
  line: {
    pos: 2,
    name: "Line",
    label: { en: "Line", de: "Linie", fr: "Ligne", it: "Linea" },
    show: true,
    width: 9,
    align: "center",
    pL: 0,
    pR: 0,
    disabled: false
  },
  destination: {
    pos: 3,
    name: "Destination",
    label: {
      en: "Destination",
      de: "Ziel",
      fr: "Destination",
      it: "Destinazione"
    },
    show: true,
    width: 62,
    align: "left",
    pL: 3,
    pR: 0,
    disabled: true
  },
  platform: {
    pos: 4,
    name: "Platform",
    label: {
      en: "Platform/Track",
      de: "Gleis/Kante",
      fr: "Voie/Quai",
      it: "Binario"
    },
    show: true,
    width: 8,
    align: "center",
    pL: 0,
    pR: 0,
    disabled: false
  },
  departureTime: {
    pos: 5,
    name: "Departure Time",
    label: {
      en: "Departure",
      de: "Abfahrt",
      fr: "Départ",
      it: "Ora di partenza"
    },
    show: true,
    width: 14,
    align: "right",
    pL: 0,
    pR: 1,
    disabled: false
  },
  via: {
    pos: 6,
    name: "Via",
    label: { en: "Via", de: "Via", fr: "Via", it: "Via" },
    show: false,
    width: 26,
    align: "left",
    pL: 1.1,
    pR: 0,
    disabled: false
  },
  remark: {
    pos: 7,
    name: "Remark",
    label: { en: "Remark", de: "Hinweis", fr: "Remarque", it: "Accenno" },
    show: false,
    width: 13,
    align: "left",
    pL: 1,
    pR: 0,
    disabled: false
  }
};

const trainColumnConfig = {
  uiColumnConfigPreset: {
    columnConfigName: "Train preset",
    columnConfigId: "train",
    supportedLayouts: ["oneColumn", "twoColumns"]
  },
  vehicleType: {
    pos: 1,
    name: "Vehicle Icon",
    label: "",
    show: false,
    width: 7,
    align: "center",
    pL: 0,
    pR: 1,
    disabled: false
  },
  line: {
    pos: 2,
    name: "Line",
    label: { en: "Line", de: "Linie", fr: "Ligne", it: "Linea" },
    show: true,
    width: 8,
    align: "center",
    pL: 0,
    pR: 0,
    disabled: false
  },
  destination: {
    pos: 4,
    name: "Destination",
    label: {
      en: "Destination",
      de: "Ziel",
      fr: "Destination",
      it: "Destinazione"
    },
    show: true,
    width: 56,
    align: "left",
    pL: 1.1,
    pR: 0,
    disabled: true
  },
  platform: {
    pos: 6,
    name: "Platform",
    label: {
      en: "Platform/Track",
      de: "Gleis/Kante",
      fr: "Voie/Quai",
      it: "Binario"
    },
    show: true,
    width: 10,
    align: "right",
    pL: 0,
    pR: 1.1,
    disabled: false
  },
  departureTime: {
    pos: 3,
    name: "Departure Time",
    label: {
      en: "Departure",
      de: "Abfahrt",
      fr: "Départ",
      it: "Ora di partenza"
    },
    show: true,
    width: 13,
    align: "left",
    pL: 2.2,
    pR: 0,
    disabled: false
  },
  via: {
    pos: 5,
    name: "Via",
    label: { en: "Via", de: "Via", fr: "Via", it: "Via" },
    show: false,
    width: 26,
    align: "left",
    pL: 1.1,
    pR: 0,
    disabled: false
  },
  remark: {
    pos: 7,
    name: "Remark",
    label: { en: "Remark", de: "Hinweis", fr: "Remarque", it: "Accenno" },
    show: true,
    width: 13,
    align: "left",
    pL: 1,
    pR: 0,
    disabled: false
  }
};

const columnConfigPresetsList = [basicColumnConfig, trainColumnConfig];

const initialColumnConfigStore = {};

export const columnConfigStore = readable(initialColumnConfigStore, set => {
  const store = {};
  columnConfigPresetsList.forEach(columnConfigPreset => {
    const columnConfigId = columnConfigPreset.uiColumnConfigPreset.columnConfigId;
    store[columnConfigId] = columnConfigPreset;
  });
  set(store);
  return () => { };
});

const columnConfigInitial = {
  columnConfigId: "",
  vehicleType: {},
  line: {},
  destination: {},
  platform: {},
  departureTime: {},
  via: {},
  remark: {}
};
export const currentColumnConfigStore = writable(columnConfigInitial);
