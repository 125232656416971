import { Utilities as U } from "../utils";

export function createFilterQueryUrl(baseApiParamModel, screenConfigurationParams) {
  console.log("createFilterQueryUrl", baseApiParamModel, screenConfigurationParams);
  const paramsPairs = U.objectKeysToArray(screenConfigurationParams);

  if (paramsPairs.length > 0) {
    const screenConfigurationConfig = {};

    paramsPairs.forEach((param) => {
      if (baseApiParamModel[param]) {
        const newValue = Object.assign({}, baseApiParamModel[param]);
        newValue.value = screenConfigurationParams[param];
        screenConfigurationConfig[param] = newValue;
      }
    });

    const separateQueryParams = [];
    const unifiedQueryParams = [];
    const unifiedQueryFilters = [];
    paramsPairs.forEach((param) => {
      const screenConfigParameter = screenConfigurationConfig[param];
      if (screenConfigParameter.value || screenConfigParameter.value === 0) {
        if (screenConfigParameter.value === "ALL")
          screenConfigParameter.value = "";

        if (screenConfigParameter.separate) {
          separateQueryParams.push(
            `${screenConfigParameter.queryName}=${screenConfigParameter.value}`
          );
        } else {
          unifiedQueryParams.push(screenConfigParameter.queryName);
          unifiedQueryFilters.push(
            `${screenConfigParameter.queryName}.${
              screenConfigParameter.filterType
            }=${encodeURIComponent(screenConfigParameter.value)}`
          );
        }
      }
    });

    let result = separateQueryParams.join("&");
    if (unifiedQueryParams.length) {
      const prefix = result.length ? "&" : "";
      result = result + prefix + "queryParams=" + unifiedQueryParams.join(",");
    }
    if (unifiedQueryFilters.length) {
      result = result + "&" + unifiedQueryFilters.join("&");
    }

    return result;
  }

  return "";
}
