import 'whatwg-fetch';
import fetchJsonp from 'fetch-jsonp';
import { logout } from "./store-user.js";
import { ErrorTypeEnum } from "./manager/enum/errorTypeEnum.js";

export class EntityNotFoundError extends Error {
  constructor(message) {
    super(message);
    this.errType = ErrorTypeEnum.NOT_FOUND;
  }
}

export function send({ method, url, data, token, eTag, useJSONP }) {

  console.log(`Sending ${method} to ${url}`);

  if (useJSONP === true) {
    const jsonpRequestPromise = fetchJsonp(url);
    return handleRequestPromice(url, jsonpRequestPromise, true);
  }

  const fetch = window.fetch;

  const opts = {
    method: method,
    headers: {},
    mode: 'cors'
  };

  if (data) {
    opts.headers['Content-Type'] = 'application/json';
    opts.body = JSON.stringify(data);
  }

  if (token) {
    console.log('Authorization Token: ' + token);
    opts.headers['Authorization'] = token;
  }

  if (eTag) {
    opts.headers['ETag'] = eTag;
  }

  const requestPromise = fetch(url, opts);

  const modifiedRequestPromise = handleRequestPromice(url, requestPromise, false);

  return modifiedRequestPromise;
}

function handleRequestPromice(url, requestPromise, useJSONP) {


  let modifiedRequestPromise = requestPromise;

  modifiedRequestPromise = modifiedRequestPromise
    .then((response) => {
      // succesfull request
      console.log("Response code: " + (response ? response.status : "unknown") + " from " + (response ? response.url : url));

      // if (response && response.headers) {
      //   for (const pair of response.headers.entries()) {
      //     console.debug("Response Header " + pair[0] + ': ' + pair[1]);
      //   }
      // }

      // if unauthorized our session has expired, logout
      if (response && response.status == 401) {
        // TODO pst: use exceptions here instead of direct call to logout
        //throw new NotAuthorizedError(response.status, response.text());
        logout();
      }

      return response;
    });

  if (useJSONP) {
    modifiedRequestPromise = modifiedRequestPromise
      .then((response) => {
        // succesfull request JSONP
        console.log("Response JSONP: ", response);
        return response.json();
      });
  } else {
    modifiedRequestPromise = modifiedRequestPromise
      .then((response) => {
        // succesfull request
        return response.text();
      }).then((text) => {
        console.log("Response body: ", text);
        return text ? JSON.parse(text) : text;
      });
  }

  modifiedRequestPromise = modifiedRequestPromise.then((json) => {
    console.debug('Response JSON: ', json);
    return json;
  });


  // error handling for failed repsonse or parsing step
  modifiedRequestPromise = modifiedRequestPromise.catch((ex) => {
    // request failed
    console.error('Sending request or parsing failed failed: ', ex);

    return undefined;
  });

  return modifiedRequestPromise;
}
