<script>
  // core
  import { onMount } from "svelte";

  // components
  import GroupPanel from "./widgets/GroupPanel.svelte";
  import ModalDialog from "./widgets/ModalDialog.svelte";
  import ColumnsDialog from "./columns/ColumnsDialog.svelte";
  import FilterDialog from "./filter/FilterDialog.svelte";
  import ColumnConfigComboBox from "./columns/ColumnConfigComboBox.svelte";
  import StationHeaderPosition from "./StationHeaderPosition.svelte";

  // utils
  import { Utilities } from "./utils.js";
  import { getQueryStringParameter } from "./util/url-search-util.js";
  import {
    getLayoutsColumnConfigPreset,
    getColumnConfigDiffFromUrl,
    getColumnConfigPresetAndDiff,
    updateColumnConfig,
    hasColumnConfigDiff
  } from "./util/column-config-utils.js";

  // enums
  import { ConfigurationTypeEnum } from "./manager/enum/configurationTypeEnum.js";

  //stores
  import { dm1 } from "./stores.js";
  import {
    columnConfigStore,
    currentColumnConfigStore
  } from "./store-column-config.js";
  import { userLoggedInStore } from "./store-user.js";

  // input properties
  export let loadedConfig;

  // model
  const filterDialogOpen = false;
  let columnsDialogOpen = false;
  let columnConfig = undefined;
  let columnConfigDiff = undefined;
  let stationHeaderPosition = undefined;

  $: $dm1, constructAdvancedColumnConfig();
  $: isDefaultColumnConfigChanged =
    selectedColumnConfigPreset === "CUSTOM_LAYOUT";
  $: selectedColumnConfigPreset = hasColumnConfigDiff(columnConfigDiff)
    ? "CUSTOM_LAYOUT"
    : $dm1.columnConfigId;
  $: if ($dm1.stationHeaderPos !== stationHeaderPosition) {
    stationHeaderPosition = $dm1.stationHeaderPos;
  }

  $: selectedColumnConfigPreset, updateColumnConfigStore();

  function updateColumnConfigStore() {
    constructAdvancedColumnConfig();
  }

  function onShowCustomStationNameClick(e) {
    let checked = e.target.checked;
    if (!checked) {
      if ($dm1.group1title !== "") {
        $dm1.group1title = "";
        $dm1.uiShowCustomStationName = false;
      }
    }
  }

  function openAdvancedColumnConfigMenu() {
    columnsDialogOpen = true;
  }

  function handleChangeInSelectedColumnConfigPreset(event) {
    const { payload } = event.detail;
    selectedColumnConfigPreset = payload;
    $dm1.columnConfigId = payload;

    if (selectedColumnConfigPreset !== "CUSTOM_LAYOUT") {
      isDefaultColumnConfigChanged = false;
      $dm1.columnConfig = "";
      columnConfigDiff = {};
    }
  }

  function constructAdvancedColumnConfig() {
    if ($columnConfigStore[$dm1.columnConfigId]) {
      const { configPreset, configDiff } = getColumnConfigPresetAndDiff(
        $columnConfigStore,
        $dm1.columnConfigId
      );

      columnConfigDiff = configDiff;
      columnConfig =
        configDiff === undefined
          ? configPreset
          : Utilities.mergeDeepRight(configPreset, configDiff);

      currentColumnConfigStore.set(
        updateColumnConfig(
          $currentColumnConfigStore,
          $dm1.columnConfigId,
          columnConfig
        )
      );
    }
  }

  function handleChangeInColumnConfig(event) {
    const { payload } = event.detail;

    columnConfig = payload.columnConfig;
    columnConfigDiff = payload.columnConfigDiff;

    if (hasColumnConfigDiff(columnConfigDiff)) {
      selectedColumnConfigPreset = "CUSTOM_LAYOUT";
    }
  }

  onMount(() => {
    constructAdvancedColumnConfig();
  });
</script>

<GroupPanel title="Departures Display Settings" hidden>
  <div>
    <div class="field" style="margin-bottom: 0.75rem;">
      <label class="label is-small">Station header</label>
    </div>
    <div class="field">
      <div class="control">
        <input
          class="is-checkradio"
          id="showHeaderCheckbox"
          type="checkbox"
          disabled={$dm1.contentLayout === 'trainSingleDeparture'}
          bind:checked={$dm1.showHeader} />
        <label
          class="label"
          for="showHeaderCheckbox"
          title={$dm1.contentLayout === 'trainSingleDeparture' ? 'Station Name Header is not supported in the current layout' : ''}>
          Show Station Name Header
        </label>
      </div>
    </div>

    {#if $dm1.showHeader && !($dm1.contentLayout === 'trainSingleDeparture') && loadedConfig && loadedConfig.type === ConfigurationTypeEnum.NORMAL}
      <div class="field">
        <div class="control">
          <input
            class="is-checkradio"
            id="uiShowCustomStationName"
            type="checkbox"
            disabled={$dm1.contentLayout === 'trainSingleDeparture' || !$dm1.showHeader}
            bind:checked={$dm1.uiShowCustomStationName}
            on:click={onShowCustomStationNameClick} />
          <label class="label" for="uiShowCustomStationName">
            Custom Text for Station Name Header
          </label>
        </div>

        {#if $dm1.uiShowCustomStationName}
          <div class="field">
            <label class="label is-small" for="headerTextInput" />
            <div class="control">
              <input
                id="headerTextInput"
                class="input"
                type="text"
                placeholder="Optional custom station name text"
                bind:value={$dm1.group1title} />
            </div>
          </div>
        {/if}

      </div>
    {/if}
    {#if $dm1.showHeader && columnConfig && stationHeaderPosition && false}
      <StationHeaderPosition {columnConfig} {stationHeaderPosition} />
    {/if}
    <div class="field" style="margin-bottom: 0.75rem;">
      <label class="label is-small">Columns order</label>
    </div>
    <div class="field">
      <div class="control">
        {#if $dm1.contentLayout !== 'trainMultipleDepartures' && $dm1.contentLayout !== 'trainSingleDeparture' && $columnConfigStore[$dm1.columnConfigId]}
          <ColumnConfigComboBox
            {selectedColumnConfigPreset}
            {isDefaultColumnConfigChanged}
            selectName=""
            on:changeInSelectedColumnConfigPreset={handleChangeInSelectedColumnConfigPreset} />

          <a on:click={openAdvancedColumnConfigMenu}>
            Advanced Column Settings
          </a>
        {:else}
          <a
            title="Advanced Column Settings not available for the current
            layout."
            style="cursor: not-allowed; color: currentColor; opacity: 0.5;">
            Advanced Column Settings
          </a>
        {/if}
      </div>
    </div>
    <div class="field" style="margin-bottom: 0.75rem;">
      <label class="label is-small">Additional Data</label>
    </div>

    <div class="field">
      <div class="control">
        <input
          id="showLineColors"
          type="checkbox"
          class="is-checkradio"
          bind:checked={$dm1.showLineColors} />
        <label class="label" for="showLineColors">Show Line Colors</label>
      </div>
    </div>

    {#if $userLoggedInStore}
      <div class="field">
        <div class="control">
          <input
            id="useTripHeadsign"
            type="checkbox"
            class="is-checkradio"
            bind:checked={$dm1.useTripHeadsign} />
          <label
            class="label"
            for="useTripHeadsign"
            title="Use the direction text from VDV data or Trip Headsign from
            GTFS data if available instead of the end station name">
            Use Trip Headsign for Destination
          </label>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <input
            id="useShortVia"
            type="checkbox"
            class="is-checkradio"
            bind:checked={$dm1.useShortVia}
            disabled={!$dm1.showVia || $dm1.contentLayout !== 'trainMultipleDepartures'} />
          <label
            class="label"
            for="useShortVia"
            title={!$dm1.showVia || $dm1.contentLayout !== 'trainMultipleDepartures' ? 'Enable Showing the Via column to use this option' : 'Short via shows only single via station per trip'}>
            Use Short Via
          </label>
        </div>
      </div>
    {/if}

    <div class="field" style="margin-bottom: 0.75rem;">
      <label class="label is-small">Departure time</label>
    </div>

    <div class="field">
      <div class="control">
        <input
          id="departureTimeRelative"
          type="checkbox"
          class="is-checkradio"
          bind:checked={$dm1.departureTimeRelative} />
        <label class="label" for="departureTimeRelative">
          Show relative time if under 30 minutes
        </label>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <input
          id="showAtStationIcon"
          type="checkbox"
          class="is-checkradio"
          disabled={$dm1.contentLayout === 'trainSingleDeparture'}
          title={$dm1.contentLayout === 'trainSingleDeparture' ? 'At station icon is not supported in the current layout' : ''}
          bind:checked={$dm1.showAtStationIcon} />
        <label class="label" for="showAtStationIcon">
          Show "At Station" icon if time under 1 minute
        </label>
      </div>
    </div>

    {#if $userLoggedInStore}
      <div class="field" style="margin-bottom: 0.75rem;">
        <label class="label is-small">Departure Real Time (RT)</label>
      </div>

      <div class="field">
        <div class="control">
          <input
            id="departureTimeRelativeWhenRt"
            type="checkbox"
            class="is-checkradio"
            bind:checked={$dm1.departureTimeRelativeWhenRt} />
          <label class="label" for="departureTimeRelativeWhenRt">
            Show relative time if RT update (&lt;60 min.)
          </label>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <input
            id="departureTimeAlwaysPlan"
            type="checkbox"
            class="is-checkradio"
            bind:checked={$dm1.departureTimeAlwaysPlan} />
          <label class="label" for="departureTimeAlwaysPlan">
            Always show plan time (delay in separate column)
          </label>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <input
            id="departureMarkLate"
            type="checkbox"
            class="is-checkradio"
            bind:checked={$dm1.departureMarkLate} />
          <label class="label" for="departureMarkLate">
            Late departure times with different color
          </label>
        </div>
      </div>
    {/if}

  </div>
</GroupPanel>
{#if columnsDialogOpen}
  <ColumnsDialog
    {columnConfig}
    {columnConfigDiff}
    {selectedColumnConfigPreset}
    bind:columnsDialogOpen
    on:changeColumnConfig={handleChangeInColumnConfig} />
{/if}
