<script>
  import { createEventDispatcher } from "svelte";
  import { toQueryString } from "./config-serialization.js";
  import { saveSegmentedScreen } from "./segmented-screen/service/segmented-screen-api.js";

  // components
  import ModalDialog from "./widgets/ModalDialog.svelte";
  import SegmentedScreenEditForm from "./segmented-screen/component/edit-screen/SegmentedScreenEditForm.svelte";

  export let userLoggedIn;

  const dispatch = createEventDispatcher();

  export let loadedConfig;

  let stagedConfigurationTitle;
  let selectedGroups = [];

  let showSaveConfigurationModal = false;

  async function handleSaveConfigClick() {
    if (!userLoggedIn) {
      dispatch("showModal", {
        text: "Please log in to save this configuration.",
      });
      return;
    }

    if (loadedConfig && loadedConfig.entity && loadedConfig.entity.id) {
      // existing normal config
      selectedGroups = null;
      await save();
    } else {
      // new config
      stagedConfigurationTitle = loadedConfig.entity.name;
      showSaveConfigurationModal = true;
    }
  }

  function handleSaveConfigFromModalClick() {
    showSaveConfigurationModal = false;

    // do not allow empty configuration name
    if (!stagedConfigurationTitle || stagedConfigurationTitle.trim() === "") {
      return;
    }

    loadedConfig.entity.name = stagedConfigurationTitle;

    save();
  }

  function handleSaveDialogCancel() {
    showSaveConfigurationModal = false;
  }

  async function save() {
    console.log("Saving segmented config");

    if (!loadedConfig || !loadedConfig.entity) {
      console.log("No config loaded to save");
      return;
    }
    const id = loadedConfig.entity.id;
    const name = loadedConfig.entity.name;
    const serializedConfig = toQueryString(loadedConfig, {
      exclude: ["entity", "eTag"],
    });

    try {
      const saved = await saveSegmentedScreen({
        id,
        name,
        serializedConfig,
        groups: selectedGroups
      });

      if (!id) {
        window.location.href = `segmented-screen-config.html?c=${saved.uid}`;
      } else {
        dispatch("showNotification", {
          success: true,
          text: "Segmented screen configuration was successfully saved",
        });
      }
    } catch (err) {
      console.error(err);
      let errorText = err.message || "Could not save segmented screen configuration";
      dispatch("showNotification", {
        success: false,
        text: errorText
      });
    }
  }
</script>

<div class="navbar-item has-dropdown is-hoverable">
  <a class="navbar-link is-arrowless" on:click={handleSaveConfigClick}>
    <span class="icon is-small">
      <i class="fas fa-save" />
    </span>
    Save
  </a>
</div>

<ModalDialog
  title="New Segmented Screen"
  active={showSaveConfigurationModal}
  okText="Save"
  showCancel="true"
  handleEnter={false}
  okHandler={handleSaveConfigFromModalClick}
  cancelHandler={handleSaveDialogCancel}>
  <div style="height: 250px">
    <SegmentedScreenEditForm
      bind:name={stagedConfigurationTitle}
      bind:selectedGroups />
  </div>
</ModalDialog>
