<script>
  import { dmBasicWritable } from "./store-basic.js";
  import { dm1, dmInitial } from "./stores.js";
  import { serializeConfig } from "./config-serialization.js";
  import ModalDialog from "./widgets/ModalDialog.svelte";
  import { userLoggedInStore } from "./store-user.js";

  export let loadedConfig = {};

  let div;

  let posUrl;
  let posPermanentUrl;

  let posRootUrl;
  dmBasicWritable.subscribe(updatedDmBasic => {
    posRootUrl = updatedDmBasic.posRootUrl.supplant({
      protocol: window.location.protocol,
      network: updatedDmBasic.network
    });
  });

  dm1.subscribe(updatedDm => {
    console.log("Config updated, update iframe");
    const serializedConfig = serializeConfig(
      $dm1,
      dmInitial,
      false,
      false
    ).replace(/\+/g, " ");

    posUrl = posRootUrl + "#/?" + serializedConfig;
    if ($userLoggedInStore) {
      posUrl += "&showWatermark=false";
    }
    posPermanentUrl;
  });

  $: posPermanentUrl =
    loadedConfig && loadedConfig.uid
      ? posRootUrl + "?c=" + loadedConfig.uid
      : undefined;

  $: if ($dm1.uiSelectedLayout === "twoColumns") {
    orientation = orientationLandscape;
  }

  const orientationLandscape = "landscape";
  const orientationPortrait = "portrait";

  let orientation = orientationLandscape;

  let showPermanentLinkNotAvaiableDialog = false;
  let showPermanentLinkDialog = false;
  let showPermanentLinkHelp = false;

  let showWontRotateScreenPopUp = false;

  function handleOnPermanentLinkClick() {
    if (loadedConfig && loadedConfig.uid && loadedConfig.type !== "TEMPLATE") {
      showPermanentLinkNotAvaiableDialog = false;
      showPermanentLinkDialog = true;
      showPermanentLinkHelp = false;
    } else {
      showPermanentLinkNotAvaiableDialog = true;
    }
  }

  function rotateScreen() {
    if (shouldRotateScreen()) {
      orientation =
        orientation === orientationLandscape
          ? orientationPortrait
          : orientationLandscape;
    } else {
      showWontRotateScreenPopUp = true;
    }
  }

  function shouldRotateScreen() {
    return $dm1.uiSelectedLayout === "twoColumns" ? false : true;
  }

  const ratioListKeys = ["43", "169", "1610", "1645"];
  const ratioListLabels = ["4:3", "16:9", "16:10", "16:4.5"];
</script>

<div id="posPreviewContainer" class="is-fullwidth" style="padding-top: 2.5rem">
  <div
    id="posPreviewIframeContainer"
    class="ratio-{$dm1.uiRatio} orientation-{orientation}">
    {#if $dm1.group1stationId}
      <iframe
        bind:this={div}
        id="posPreviewIframe"
        src={posUrl}
        title="Departures Monitor Preview" />
    {/if}
  </div>

  <div id="posPreviewControlsContainer">

    <div class="pos-control is-inline-block">
      <p class="control has-icons-left">
        <span class="select">
          <select bind:value={$dm1.uiRatio}>
            {#each ratioListKeys as ratioKey, i}
              <option value={ratioKey} selected={ratioKey == $dm1.uiRatio}>
                {ratioListLabels[i]}
              </option>
            {/each}
          </select>
        </span>
        <span class="icon is-small is-left">
          <i class="fas fa-window-maximize" />
        </span>
      </p>
    </div>

    <div class="pos-control control is-inline-block">
      <a on:click={() => rotateScreen()}>
        <span class="icon is-small">
          <i class="fas fa-sync-alt" />
        </span>
        Rotate
      </a>
    </div>

    <div class="pos-control control is-inline-block">
      <a href={posUrl} target="_blank">
        <span class="icon is-small">
          <i class="fas fa-external-link-alt" />
        </span>
        Preview in New Window
      </a>
    </div>

    <div class="pos-control control is-inline-block">
      <a on:click={handleOnPermanentLinkClick}>
        <span class="icon is-small">
          <i class="fas fa-link" />
        </span>
        Permanent Link
      </a>
    </div>

  </div>

  <!-- <a class="help is-small has-text-centered" href={posUrl} target="_blank">
    {posUrl}
  </a> -->

  <ModalDialog
    title="Permanent Link for Monitor's Browser"
    bind:active={showPermanentLinkDialog}>

    <div style="background: #fff">

      <div class="field has-addons">
        <div class="control is-expanded">
          <input
            class="input"
            type="text"
            id="posPermanentUrlInputText"
            bind:value={posPermanentUrl}
            readonly
            onclick="this.select()"
            onfocus="this.select()" />
        </div>
        <p class="control">
          <a
            class="button is-medum"
            onclick="document.getElementById('posPermanentUrlInputText').select();
            document.execCommand('copy');">
            Copy
          </a>
        </p>

      </div>

      <a
        href={posPermanentUrl}
        target="_blank"
        class="is-pulled-right is-clearfix">
        <span class="icon is-small">
          <i class="fas fa-external-link-alt" />
        </span>
        Open in new Window
      </a>

      <a
        target="_blank"
        class="is-clearfix"
        on:click={() => (showPermanentLinkHelp = !showPermanentLinkHelp)}>
        <span class="icon is-small">
          <i class="fas fa-question-circle" />
        </span>
        Help
      </a>

      {#if showPermanentLinkHelp}
        <p class="help is-clearfix is-block">
          Please configure this link in the Departure Monitor's Browser.
        </p>
        <p class="help">
          Any future change will appear on the monitor with delay of up to 30
          seconds after saving the configuration.
        </p>
        <p class="help">
          Never configure temporary URL with parameters since changes applied to
          the configuration will not appear on the monitor.
        </p>
      {/if}

    </div>
  </ModalDialog>

  <ModalDialog bind:active={showPermanentLinkNotAvaiableDialog} width="600">
    <div style="background: #fff">

      <article class="media">
        <div class="media-left">
          <span class="icon has-text-warning is-large">
            <i class="fas fa-exclamation-triangle fa-3x" />
          </span>
        </div>
        <div class="media-content">
          <div class="content">
            {#if !loadedConfig.uid}
              <p>
                Permanent link is not available until the configuration is
                saved.
              </p>
            {:else if loadedConfig.type === 'TEMPLATE'}
              <p>
                Permanent link is not available for templates.
                <br />
                Please save new configuration from the template to get permanent
                link.
              </p>
            {/if}
          </div>
        </div>
      </article>
    </div>
  </ModalDialog>

  <ModalDialog
    active={showWontRotateScreenPopUp}
    okText="OK"
    okHandler={() => (showWontRotateScreenPopUp = false)}>
    <div class="rotation-screen-pop-up">
      <article class="media">
        <div class="media-left">
          <span class="icon has-text-warning is-large">
            <i class="fas fa-exclamation-triangle fa-3x" />
          </span>
        </div>
        <div class="media-content">
          <p>"Two column" layout cannot be rotated to portrait mode.</p>
        </div>
      </article>
    </div>
  </ModalDialog>
</div>
