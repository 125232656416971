import * as httpClient from "../api.js";
import { cleanEntity } from "../util/entity-utils";
import { dmBasicInitial } from "../store-basic.js";

// utils
import { Utilities as U } from "../utils.js";
import { createFilterQueryUrl } from "../util/query-utils";

// enum
import { ConfigurationTypeEnum } from "./enum/configurationTypeEnum.js";
import { ScreenConfigurationDeleteReasonEnum } from "./enum/screenConfigurationDeleteReasonEnum.js";

// errors
import {
  logError,
  IllegalArgumentException,
  EntityNotCreatedError,
  EntityNotFoundError,
  EntityNotModifiedError,
  ForbbidenServiceError
} from "../util/error-utils.js";
const apiUrl = dmBasicInitial.managmentApiRootUrl + "screen-configurations";

const baseApiParamModel = {
  page: {
    separate: true,
    queryName: "page"
  },
  size: {
    separate: true,
    queryName: "size"
  },
  sort: {
    separate: true,
    queryName: "sort"
  },
  monitorType: {
    separate: false,
    queryName: "monitorType",
    filterType: "equals"
  },
  searchQuery: {
    separate: false,
    queryName: "name",
    filterType: "contains"
  },
  station: {
    separate: false,
    queryName: "stationId",
    filterType: "equals"
  },
  platform: {
    separate: false,
    queryName: "platformId",
    filterType: "equals"
  },
  type: {
    separate: false,
    queryName: "type",
    filterType: "equals"
  },
  parentConfigurationId: {
    separate: false,
    queryName: "parentConfigurationId",
    filterType: "equals"
  },
  includeInfoMessages: {
    separate: true,
    queryName: "includeInfoMessages"
  }
};

export const baseEntityModel = {
  uid: "",
  name: "",
  description: "",
  serializedDmConfig: "",
  type: ConfigurationTypeEnum.NORMAL,
  locationCity: "",
  locationAddress: "",
  monitorType: "DEPARTURES",
  stationId: "",
  stationName: "",
  platform: "",
  platformId: "",
  locationType: "INDOOR",
  locationCoordinates: "",
  hardwareName: "",
  hardwareDescription: "",
  screenName: "",
  screenDescription: "",
  screenResolution: "",
  screenOrientation: "LANDSCAPE",
  operatingSystem: "",
  operatingSystemVersion: "",
  browser: "",
  browserVersion: "",
  remoteDesktopDescription: "",
  remoteDesktopUrl: ""
};

export async function getScreenConfigurationEntities(screenConfigurationParams, healthStatus, yearAndMonth) {
  console.log("getScreenConfigurationEntities", screenConfigurationParams, healthStatus, yearAndMonth);

  let requestUrl = `${apiUrl}`;

  if (typeof screenConfigurationParams !== "object") {
    logError("Error: request params");
    return undefined;
  }

  const filterQuery = createFilterQueryUrl(baseApiParamModel, screenConfigurationParams);

  if (filterQuery) {
    requestUrl = requestUrl + "?" + filterQuery;

    if (healthStatus) {
      requestUrl = requestUrl + "&healthStatus=true";
      if(yearAndMonth) {
        requestUrl = requestUrl + `&yearMonth=${yearAndMonth}`
      }
    }
  }

  return httpClient.get(requestUrl);
}

export function getScreenConfigurationEntitiesCount(screenConfigurationParams) {
  console.log("getScreenConfigurationEntitiesCount", screenConfigurationParams);

  let requestUrl = `${apiUrl}/count`;

  if (typeof screenConfigurationParams !== "object") {
    logError("Error: request params");
    return undefined;
  }

  const filterQuery = createFilterQueryUrl(baseApiParamModel, screenConfigurationParams);

  if (filterQuery) {
    requestUrl = requestUrl + "?" + filterQuery;
  }


  return httpClient.get(requestUrl);
}

export async function getEntity(id) {
  console.log("Getting Screen Configuration by ID: " + id);
  if (!id) {
    logError("Error: Attempt to load Screen configuration without ID");
    throw new IllegalArgumentException(
      "Attempt to load screen configuration withoud ID"
    );
  }
  const requestUrl = `${apiUrl}/${id}`;
  const result = await httpClient.get(requestUrl);
  if (result && result.id && result.uid) {
    // add fields that are missing in the JSON if empty or null
    if (result.description === undefined) {
      result.description = "";
    }

    if (result.locationCity === undefined) {
      result.locationCity = "";
    }

    if (result.locationAddress === undefined) {
      result.locationAddress = "";
    }

    if (result.locationCoordinates === undefined) {
      result.locationCoordinates = "";
    }

    if (!result.transportNetwork) {
      result.transportNetwork = {};
    }
    return result;
  } else {
    throw new EntityNotFoundError("Entity was not found");
  }
}

export async function getEntityByUid(uid) {
  console.log("Getting Screen Configuration by UID: " + uid);
  if (!uid) {
    throw new IllegalArgumentException(
      "Attempt to load screen configuration without UID"
    );
  }
  const requestUrl = `${apiUrl}/uid/${uid}`;
  const result = await httpClient.get(requestUrl);
  if (result && result.id && result.uid) {
    return result;
  } else {
    throw new EntityNotFoundError("Entity was not found");
  }
}

export async function createEntity(entity, updateFromParent) {
  console.log("Creating Screen Configuration: " + JSON.stringify(entity));
  if (entity.id || entity.uid) {
    throw new IllegalArgumentException(
      "Attempt to create screen configuration with already existing id or uid"
    );
  }

  const requestUrl = apiUrl + (updateFromParent ? '?updateFromParent=true' : '');

  const result = await httpClient.post(requestUrl, cleanEntity(entity));
  if (result && result.id && result.uid) {
    console.log("Created Screen Configuration: " + JSON.stringify(result));
    return result;
  } else {
    let msg = "Entity was not created";
    if (result && result.message) {
      msg = result.message;
    }
    throw new EntityNotCreatedError(msg);
  }
}

export async function getChildConfigurations(id) {
  const requestUrl = `${apiUrl}/child/${id}`;
  if (!id) {
    throw new IllegalArgumentException(
      "Attempt to find child configurations without parent id"
    );
  }

  const result = httpClient.get(requestUrl);
  return result;
}

export async function updateEntity(entity, childConfigurationsIds, includeChildUpdateResult = false) {
  console.log(
    "Updating Screen Configuration: " + (entity ? JSON.stringify(entity) : "")
  );

  if (!entity || !entity.id) {
    logError("Error: Attempt to edit  Screen configuration without ID");
    throw new IllegalArgumentException(
      "Attempt to edit screen configuration without ID"
    );
  }

  // remove template object if any
  if (entity.parentConfiguration && !entity.parentConfiguration.id) {
    delete entity.parentConfiguration;
  }
  const requestUrl = `${apiUrl}`;

  const requestObject = {
    screenConfiguration: cleanEntity(entity)
  };

  if (childConfigurationsIds && childConfigurationsIds.length > 0) {
    requestObject.updateChildren = true;
    requestObject.childConfigurationsIds = childConfigurationsIds;
  }

  if (entity.transportNetwork && !entity.transportNetwork.id) {
    delete entity.transportNetwork;
  }
  const response = await httpClient.put(requestUrl, requestObject);
  if (includeChildUpdateResult && response && response.value) {
    return response;
  }
  const result = response.value;
  if (result && result.id && result.uid) {
    console.log("Updated Screen Configuration: " + JSON.stringify(result));
    return result;
  } else {
    throw new EntityNotModifiedError("Failed to update screen configuration, result: ", result);
  }
}

export async function deleteEntity(id) {
  console.log("Deleting Screen Configuration: " + id);
  if (!id) {
    throw new IllegalArgumentException(
      "Attempt to delete screen configuration withoud ID"
    );
  }
  const requestUrl = `${apiUrl}/${id}`;
  const result = await httpClient.del(requestUrl);
  console.log("Deleted Screen Configuration: ", id, result);

  if (result.status) {
    switch (result.reason) {
      case ScreenConfigurationDeleteReasonEnum.HAS_ACTIVE_INFO_MESSAGES:
        throw new ForbbidenServiceError(
          "Screen configuration has active info messages",
          ScreenConfigurationDeleteReasonEnum.HAS_ACTIVE_INFO_MESSAGES
        );
      case ScreenConfigurationDeleteReasonEnum.HAS_CHILD_CONFIGURATIONS:
        throw new ForbbidenServiceError(
          "Template configuration has child configurations",
          ScreenConfigurationDeleteReasonEnum.HAS_CHILD_CONFIGURATIONS
        );
    }
  }
  return result;
}

export async function removeConstraintsFromScreenConfiguration(screenId) {
  const requestUrl = `${apiUrl}/${screenId}/remove-constraints`;
  console.log("Screen Id to remove constraints: ", screenId);

  if (!screenId) {
    throw new IllegalArgumentException(
      "Attempt to delete screen constraints without any screen ids"
    );
  }

  return await httpClient.put(requestUrl);
}

export async function getStationsOnScreenConfigurations(monitorType) {
  let requestUrl = `${apiUrl}/stations`;

  if (monitorType) {
    requestUrl += "?monitorType=" + monitorType;
  }
  const result = await httpClient.get(requestUrl);

  return result;
}

export async function getScreenConfigurationsUidName(type, limit) {
  let requestUrl = `${apiUrl}/uid-name?`;

  if (type) {
    requestUrl += "type=" + type + "&";
  }
  if (limit) {
    requestUrl += "limit=" + limit + "&";
  }
  const result = await httpClient.get(requestUrl);

  return result;
}

export async function deleteAll(screenConfigurationIds) {
  console.log("Bulk delete screen configurations", screenConfigurationIds);
  const idsParam = screenConfigurationIds.join(',')
  const url = `${apiUrl}?ids=${idsParam}`;
  return httpClient.del(url);
}
