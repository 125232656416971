<script>
  // core
  import { onMount, createEventDispatcher } from "svelte";

  // input properties
  export let label;

  let labelEn = "";
  let labelDe = "";
  let labelFr = "";
  let labelIt = "";

  // dispatcher
  const dispatch = createEventDispatcher();

  function handleColumnLabelSave() {
    const payload = {
      en: labelEn,
      de: labelDe,
      fr: labelFr,
      it: labelIt
    };
    dispatch("saveColumnLabel", {
      label: payload
    });
  }

  function handleColumnLabelDiscard() {
    dispatch("discardColumnLabelChanges");
  }

  onMount(() => {
    labelEn = label.en;
    labelDe = label.de;
    labelFr = label.fr;
    labelIt = label.it;
  });
</script>

{#if label}
  <div class="field">
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="English label"
        bind:value={labelEn} />
    </div>
  </div>
  <div class="field">
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="German label"
        bind:value={labelDe} />
    </div>
  </div>
  <div class="field">
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="French label"
        bind:value={labelFr} />
    </div>
  </div>
  <div class="field">
    <div class="control">
      <input
        class="input"
        type="text"
        placeholder="Italian label"
        bind:value={labelIt} />
    </div>
  </div>
  <div class="field is-grouped">
    <div class="control">
      <button
        type="button"
        class="button is-success"
        on:click={handleColumnLabelSave}>
        <span class="icon is-small">
          <i class="fas fa-check" />
        </span>
      </button>
    </div>
    <div class="control">
      <button
        type="button"
        class="button is-danger"
        on:click={handleColumnLabelDiscard}>
        <span class="icon is-small">
          <i class="fas fa-times" />
        </span>
      </button>
    </div>
  </div>
{/if}
