<script>
  import { dm1 } from "../stores.js";
  import { dmBasic } from "../store-basic.js";
  import { onMount } from "svelte";
  import * as api from "../api.js";

  const station = $dm1.group1stationId;

  $: allPlatforms = $dm1.platformIds.length === 0;

  let platformsList;

  onMount(async () => {
    if (station) {
      const url = $dmBasic.platformsUrl.supplant({
        apiRootUrl: $dmBasic.apiRootUrl,
        network: $dmBasic.network,
        stationId: station
      });
      const platformsJson = await api.getWithOptionalJsonP(url);
      console.log("JSON: " + platformsJson);
      platformsList = platformsJson;
    }
  });

  function onAllPlatformsClick(e) {
    if (e.target.checked) {
      $dm1.platformIds = [];
    }
  }
</script>

<style>
  .field > label {
    width: 200px;
    line-height: 2;
  }
  .w80p {
    width: 80%;
  }
</style>

<div class="field is-grouped">
  <label class="label">Platforms</label>
  <div class="control w80p">
    <input
      class="input "
      type="text"
      placeholder="Comma separated Platform IDs to show"
      bind:value={$dm1.platformIds} />
  </div>
</div>

<div class="field">
  <div class="control">
    <label class="checkbox">
      <input
        type="checkbox"
        bind:checked={allPlatforms}
        on:click={onAllPlatformsClick} />
      All Platforms
    </label>
  </div>
</div>

{#if platformsList}
  <div id="platformsList">
    {#each platformsList.stoppingPlaces as stoppingPlace}
      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input
              type="checkbox"
              bind:group={$dm1.platformIds}
              value={stoppingPlace.externalId} />
            {stoppingPlace.displayName} :
            <strong>[ {stoppingPlace.platform} ]</strong>
            {stoppingPlace.externalId}
          </label>
        </div>
      </div>
    {/each}
  </div>
{/if}
