<script>
  // core
  import { onMount } from "svelte";

  // component
  import Select from "svelte-select";

  // service
  import {
    getAllScreenGroups,
    createScreenGroup
  } from "../../service/segmented-screen-api";

  export let name = "";
  export let allGroups = [];
  export let selectedGroups = [];

  // groups
  const getSelectionLabel = (option) => option.name;
  const getOptionLabel = (option, filterText) =>
    option.isCreator ? `Create "${filterText}"` : option.name;
  const createItemFunc = (filterText) => {
    return {
      name: filterText,
      _created: true,
    };
  };

  let loadingGroups = false;
  let dataError = undefined;

  onMount(() => {
    loadingGroups = true;

    getAllScreenGroups()
      .then((data) => {
        allGroups = data;
      })
      .catch((err) => {
        dataError = "Failed to load screen groups";
        console.error(dataError, err);
      })
      .finally(() => (loadingGroups = false));
  });

  function createScreenGroupContext() {
    return {
      active: false,
      name: "",
      error: null,
    };
  }

  async function handleSelect(e) {
    const selected = e.detail.filter(it => it.name.length);
    console.log("handleSelect", selected);

    const newGroups = [];

    for (const [index, val] of selected.entries()) {
      if (val._created) {
        console.log(`group at index ${index} is new`);
        const created = await createScreenGroup(val.name);
        newGroups.push(created);
        selected[index] = created;
      }
    }

    if (newGroups.length) {
      allGroups = [...allGroups, ...newGroups];
    }
    selectedGroups = selected;

    console.log("allGroups", allGroups);
    console.log("selectedGroups", selectedGroups);
  }
</script>

<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label has-text-weight-bold">Name</label>
  </div>
  <div class="field-body">
    <div class="field">
      <p class="control is-expanded">
        <input
          type="text"
          bind:value={name}
          class="input"
          placeholder="Enter segmented screen name" />
      </p>
    </div>
  </div>
</div>

<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label class="label has-text-weight-bold">Groups</label>
  </div>
  <div class="field-body">
    <div class="field">
      <div class="control">
        <Select
          items={allGroups}
          optionIdentifier="id"
          selectedValue={selectedGroups}
          isMulti={true}
          isCreatable={true}
          hideEmptyState={true}
          placeholder="Search for screen groups"
          {getSelectionLabel}
          {getOptionLabel}
          createItem={createItemFunc}
          on:select={handleSelect} />
      </div>
    </div>
  </div>
</div>
