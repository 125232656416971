<script>
  import GroupPanel from "./widgets/GroupPanel.svelte";
  import UpDownSpinner from "./widgets/UpDownSpinner.svelte";

  import { dm1 } from "./stores.js";
</script>

<style>

</style>

<GroupPanel title="Header" hidden>

  <div class="field">
    <div class="control">
      <input
        class="is-checkradio"
        id="showHeaderCheckbox"
        type="checkbox"
        disabled={$dm1.contentLayout === 'trainSingleDeparture'}
        bind:checked={$dm1.showTopHeader} />
      <label
        class="label"
        for="showHeaderCheckbox"
        title={$dm1.contentLayout === 'trainSingleDeparture' ? 'Header is not supported in the current layout' : ''}>
        Show Header
      </label>
    </div>
  </div>

  {#if $dm1.showTopHeader}
    <div class="field">
      <label class="label is-small" for="headerTextInput">Header Text</label>
      <div class="control">
        <input
          id="headerTextInput"
          class="input"
          type="text"
          placeholder="Optional text displayed centrally in the Header"
          bind:value={$dm1.headerText} />
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label is-small" for="headerHeight">
            Header Height %
          </label>
          <div class="control">
            <UpDownSpinner id="headerHeight" bind:value={$dm1.headerHeight} />
          </div>
        </div>
        <div class="field">
          <label class="label is-small" for="headerFontScale">
            Header Text Size Scale
          </label>
          <div class="control">
            <UpDownSpinner
              id="headerFontScale"
              floatStep="0.1"
              bind:value={$dm1.headerFontScale} />

          </div>
        </div>

      </div>
    </div>
  {:else}
    <p class="is-small">
      {#if $dm1.contentLayout === 'trainSingleDeparture'}
        Header is not supported in the current Layout.
      {:else}
        Header is set to hidden. Enable &quot;Show Header&quot; to see options.
      {/if}
    </p>
  {/if}

</GroupPanel>

<GroupPanel title="Footer" hidden>

  <div class="field">
    <div class="control">
      <input
        class="is-checkradio"
        id="showFooterCheckbox"
        type="checkbox"
        disabled={$dm1.contentLayout === 'trainSingleDeparture'}
        bind:checked={$dm1.showFooter} />
      <label
        class="label"
        title={$dm1.contentLayout === 'trainSingleDeparture' ? 'Footer is not supported in the current layout' : ''}
        for="showFooterCheckbox">
        Show Footer
      </label>
    </div>
  </div>

  {#if $dm1.showFooter}
    <div class="field">
      <label class="label is-small" for="foooterTextInput">Footer Text</label>
      <div class="control">
        <input
          id="foooterTextInput"
          class="input"
          type="text"
          placeholder="Optional text displayed centrally in the Footer"
          bind:value={$dm1.footerText} />
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label is-small" for="footerHeight">
            Footer Height %
          </label>
          <div class="control">
            <UpDownSpinner id="footerHeight" bind:value={$dm1.footerHeight} />
          </div>
        </div>
        <div class="field">
          <label class="label is-small" for="footerFontScale">
            Footer Text Size Scale
          </label>
          <div class="control">
            <UpDownSpinner
              id="footerFontScale"
              floatStep="0.1"
              bind:value={$dm1.footerFontScale} />
          </div>
        </div>

      </div>
    </div>

    <div class="field">
      <div class="control">
        <input
          class="is-checkradio"
          id="showLogoCheckbox"
          type="checkbox"
          bind:checked={$dm1.showLogo} />
        <label class="label" for="showLogoCheckbox">Show Logo</label>
      </div>
    </div>

    {#if $dm1.showLogo}
      <div class="field">
        <label class="label is-small" for="logoUrlInput">Logo URL</label>
        <div class="control">
          <input
            id="logoUrlInput"
            class="input"
            type="text"
            placeholder="Optional URL of image to use as logo"
            bind:value={$dm1.logoUrl} />
        </div>
      </div>
    {/if}

    <div class="field">
      <div class="control">
        <input
          class="is-checkradio"
          id="showDisclaimerCheckbox"
          type="checkbox"
          bind:checked={$dm1.showDisclaimer} />
        <label class="label" for="showDisclaimerCheckbox">Show Disclaimer Link</label>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <input
          class="is-checkradio"
          id="showClockCheckbox"
          type="checkbox"
          bind:checked={$dm1.showClock} />
        <label class="label" for="showClockCheckbox">Show Digital Clock</label>
      </div>
    </div>

    {#if $dm1.showClock}
      <div class="field">
        <label class="label is-small" for="footerTimeFormat">
          Digital Clock Format
        </label>
        <div class="control">
          <div class="select">
            <select id="footerTimeFormat" bind:value={$dm1.footerTimeFormat}>
              <option value="dddd, DD. MMMM YYYY, HH:mm">
                Day of week, Date and Time (dddd, DD. MMMM YYYY, HH:mm)
              </option>
              <option value="DD. MMMM YYYY, HH:mm">
                Date and Time (DD. MMMM YYYY, HH:mm)
              </option>
              <option value="dddd, DD.MM.YYYY, HH:mm">
                Day of week, Short Date and Time (dddd, DD.MM.YYYY, HH:mm)
              </option>
              <option value="DD.MM.YYYY, HH:mm">
                Short Date and Time (DD.MM.YYYY, HH:mm)
              </option>
              <option value="HH:mm">Time (HH:mm)</option>
            </select>
          </div>

        </div>
      </div>
    {/if}
  {:else}
    <p class="is-small">
      {#if $dm1.contentLayout === 'trainSingleDeparture'}
        Footer is not supported in the current Layout.
      {:else}
        Footer is set to hidden. Enable &quot;Show Header&quot; to see options.
      {/if}
    </p>
  {/if}

</GroupPanel>

<GroupPanel title="Departures Table" hidden>

  {#if $dm1.contentLayout === 'basic' || $dm1.contentLayout === 'trainMultipleDepartures'}
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label is-small" for="footerHeight">
            Departures Table Height %
          </label>
          <div class="control">
            <UpDownSpinner id="contentHeight" bind:value={$dm1.contentHeight} />
          </div>
        </div>
        <div class="field">
          <label class="label is-small" for="departureFontScale">
            Departures Text Size Scale
          </label>
          <div class="control">
            <UpDownSpinner
              id="departureFontScale"
              floatStep="0.1"
              bind:value={$dm1.departureFontScale} />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label
            class="label is-small"
            for="titleRows"
            class:disabled={!$dm1.showHeader}>
            Station Name Rows
          </label>
          <div class="control">
            <UpDownSpinner
              id="titleRows"
              floatStep="0.1"
              bind:value={$dm1.titleRows}
              title={!$dm1.showHeader ? 'Station Name Header is not shown' : ''}
              disabled={!$dm1.showHeader} />
          </div>
        </div>
        <div class="field">
          <label
            class="label is-small"
            for="titleFontScale"
            class:disabled={!$dm1.showHeader}>
            Station Name Text Size Scale
          </label>
          <div class="control">
            <UpDownSpinner
              id="titleFontScale"
              floatStep="0.1"
              disabled={!$dm1.showHeader}
              title={!$dm1.showHeader ? 'Station Name Header is not shown' : ''}
              bind:value={$dm1.titleFontScale} />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label is-small" for="legendRows">Legend Rows</label>
          <div class="control">
            <UpDownSpinner
              id="legendRows"
              floatStep="0.1"
              bind:value={$dm1.legendRows} />
          </div>
        </div>
        <div class="field">
          <label class="label is-small" for="legendFontScale">
            Legend Text Size Scale
          </label>
          <div class="control">
            <UpDownSpinner
              id="legendFontScale"
              floatStep="0.1"
              bind:value={$dm1.legendFontScale} />
          </div>
        </div>
      </div>
    </div>
  {:else if $dm1.contentLayout === 'trainSingleDeparture'}
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label is-small" for="bodyMarginHor">
            Padding Horizontal %
          </label>
          <div class="control">
            <UpDownSpinner
              id="bodyMarginHor"
              floatStep="0.5"
              bind:value={$dm1.bodyMarginHor} />
          </div>
        </div>
        <div class="field">
          <label class="label is-small" for="bodyMarginVer">
            Padding Vertical %
          </label>
          <div class="control">
            <UpDownSpinner
              id="bodyMarginVer"
              floatStep="0.5"
              bind:value={$dm1.bodyMarginVer} />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label is-small" for="row1Height">
            Top Row Height %
          </label>
          <div class="control">
            <UpDownSpinner id="row1Height" bind:value={$dm1.row1Height} />
          </div>
        </div>
        <div class="field">
          <label class="label is-small" for="row2MarginTop">
            Top / Bottom Row Separation %
          </label>
          <div class="control">
            <UpDownSpinner id="row2MarginTop" bind:value={$dm1.row2MarginTop} />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label is-small" for="colLineWidth">
            Line / Delay Region Width %
          </label>
          <div class="control">
            <UpDownSpinner id="colLineWidth" bind:value={$dm1.colLineWidth} />
          </div>
        </div>
        <div class="field">
          <label class="label is-small" for="colTimeWidth">
            Time Region Width %
          </label>
          <div class="control">
            <UpDownSpinner id="colTimeWidth" bind:value={$dm1.colTimeWidth} />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label is-small" for="colDepartureFontScale">
            Text Size
          </label>
          <div class="control">
            <UpDownSpinner
              id="colDepartureFontScale"
              floatStep="0.1"
              bind:value={$dm1.colDepartureFontScale} />
          </div>
        </div>
        <div class="field">
          <label class="label is-small" for="colMessageFontScale">
            Info Message Text Size
          </label>
          <div class="control">
            <UpDownSpinner
              id="colMessageFontScale"
              floatStep="0.1"
              bind:value={$dm1.colMessageFontScale} />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label is-small" for="screenWidth">
            Custom Screen Width (px)
          </label>
          <div class="control">
            <UpDownSpinner id="screenWidth" bind:value={$dm1.screenWidth} />
          </div>
        </div>
        <div class="field">
          <label class="label is-small" for="screenHeight">
            Custom Screen Height (px)
          </label>
          <div class="control">
            <UpDownSpinner id="screenHeight" bind:value={$dm1.screenHeight} />
          </div>
        </div>
      </div>
    </div>
  {:else}
    <p class="is-small">
      Departures table options are not supported in the current layout.
    </p>
  {/if}

</GroupPanel>

<GroupPanel title="Analog Clock" hidden>
  <div class="field">
    <div class="control">
      <input
        class="is-checkradio"
        id="showAnalogCheckbox2"
        type="checkbox"
        disabled={$dm1.contentLayout === 'trainSingleDeparture'}
        bind:checked={$dm1.showAnalogClock} />
      <label
        class="label"
        for="showAnalogCheckbox2"
        title={$dm1.contentLayout !== 'trainMultipleDepartures' ? 'Analog clock is not supported in the current layout' : ''}>
        Show Analog Clock
      </label>
    </div>
  </div>

  {#if $dm1.contentLayout !== 'trainSingleDeparture'}
    {#if $dm1.showAnalogClock}
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field is-narrow">
            <label class="label is-small" for="clockScale">
              Clock Size Scale
            </label>
            <p class="control">
              <UpDownSpinner
                id="clockScale"
                floatStep="0.1"
                bind:value={$dm1.clockScale} />
            </p>
          </div>

          <div class="field">
            <label class="label is-small" />
            <p class="control" />
          </div>

        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <label class="label is-small" for="clockPaddingHor">
              Horizontal Offset %
            </label>
            <p class="control">
              <UpDownSpinner
                id="clockPaddingHor"
                floatStep="0.5"
                bind:value={$dm1.clockPaddingHor} />
            </p>
          </div>
          <div class="field">
            <label class="label is-small" for="clockPaddingVer">
              Vertical Offset %
            </label>
            <p class="control">
              <UpDownSpinner
                id="clockPaddingVer"
                floatStep="0.5"
                bind:value={$dm1.clockPaddingVer} />
            </p>
          </div>
        </div>
      </div>
    {:else}
      <p class="is-small">
        Analog is set to hidden. Enable &quot;Show Analog Clock&quot; to see
        options.
      </p>
    {/if}
  {:else}
    <p class="is-small">Analog clock is not supported in the current layout.</p>
  {/if}
</GroupPanel>
