<script>
  import { createEventDispatcher } from "svelte";

  import ColumnsTableRow from "./ColumnsTableRow.svelte";

  export let visibleColumns = undefined;
  export let selectedTabIndex;
</script>

<table class="table is-hoverable is-fullwidth">
  <thead>
    <tr>
      <th>
        <abbr title="Position">#</abbr>
      </th>
      <th>Column</th>
      <th>Label</th>
      <th>Alignment</th>
      <th>Padding Left (%)</th>
      <th>Padding Right (%)</th>
    </tr>
  </thead>
  <tbody>
    {#each visibleColumns as column, i}
      <ColumnsTableRow
        {selectedTabIndex}
        configName={column.configName}
        pos={i + 1}
        name={column.name}
        alignment={column.align}
        paddingLeft={column.pL}
        paddingRight={column.pR}
        label={column.label}
        on:spacingChange
        on:labelChange />
    {/each}
  </tbody>
</table>
