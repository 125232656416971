import pick from 'lodash/pick';

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with relationship fields with empty an empty id and thus
 * resulting in a 500.
 *
 * @param entity Object to clean.
 */
export function cleanEntity(entity) {
  const result = entity;
  if (entity && entity.id === '' || entity.id === -1) {
    delete entity.id;
  }
  return result;
}

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export function mapIdList(idList) {
  idList.filter((entityId) => entityId !== '').map((entityId) => ({ id: entityId }));
}
