<script>
  const items = [
    { key: "", label: "System font (Arial, Helvetica)" },
    { key: "Roboto:400", label: "Roboto", hasSemiBold: false },
    { key: "Roboto:700", label: "Roboto Bold" },
    { key: "Open Sans:400", label: "Open Sans" },
    { key: "Open Sans:600", label: "Open Sans Semi-Bold" },
    { key: "Open Sans:700", label: "Open Sans Bold" },
    { key: "Montserrat:400", label: "Montserrat" },
    { key: "Montserrat:600", label: "Montserrat Semi-Bold" },
    { key: "Montserrat:700", label: "Montserrat Bold" },
    { key: "Source Sans Pro:400", label: "Source Sans Pro" },
    { key: "Source Sans Pro:600", label: "Source Sans Pro Semi-Bold" },
    { key: "Source Sans Pro:700", label: "Source Sans Pro Bold" },
    { key: "Oxygen", label: "Oxygen", hasSemiBold: false },
    { key: "Oxygen:700", label: "Oxygen Bold" },
    { key: "Nunito Sans:400", label: "Nunito Sans" },
    { key: "Nunito Sans:600", label: "Nunito Sans Semi-Bold" },
    { key: "Nunito Sans:700", label: "Nunito Sans Bold" },
    { key: "Poppins:400", label: "Poppins" },
    { key: "Poppins:600", label: "Poppins Semi-Bold" },
    { key: "Poppins:700", label: "Poppins Bold" },
    { key: "Hind:400", label: "Hind" },
    { key: "Hind:600", label: "Hind Semi-Bold" },
    { key: "Hind:700", label: "Hind Bold" }
  ];

  export let showInherit = false;

  if (showInherit) {
    items.unshift("Inherit");
  }
  export let selected = items[0].key;

  export let icon = "font";
  export let className = "";
</script>

<div class="control has-icons-left {className}">
  <div class="select is-fullwidth">
    <select bind:value={selected}>
      {#each items as item}
        <option value={item.key}>{item.label}</option>
      {/each}
    </select>
  </div>
  <span class="icon is-small is-left">
    <i class="fas fa-{icon}" />
  </span>
</div>

<!-- <div class="control has-icons-left w40">
  <div class="select is-fullwidth">
    <select bind:value={selectedWeight}>
      <option value="400">Normal</option>
      <option value="600">Semi-Bold</option>
      <option value="700">Bold</option>
    </select>
  </div>
  <span class="icon is-small is-left">
    <i class="fas fa-bold" />
  </span>
</div> -->
