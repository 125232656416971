<script>
  import { onMount } from "svelte";

  export let showInherit = false;

  export let defaultColor = undefined;

  export let selected = undefined;

  export let icon = "fill-drip";
  export let className = "";

  let selectedOrDefault;

  function clear() {
    selected = undefined;
    init();
  }

  onMount(() => {
    init();
  });

  $: defaultColor, selected, init();

  function init() {
    selectedOrDefault = selected ? selected : defaultColor;
  }

  function changeSelected() {
    if (selectedOrDefault == defaultColor) {
      selected = undefined;
    } else {
      selected = selectedOrDefault;
    }
  }
</script>

<style>
  .colorRectangle {
    left: 30px;
    height: 20px !important;
    width: 20px !important;
    top: 8px !important;
    border: 1px solid #000;
  }

  .selectPadding {
    padding-left: 3em !important;
  }

  .colorPicker {
    width: 100%;
    max-width: 100%;
    height: 2.25em;
    position: relative;
    cursor: pointer;
    display: block;
    font-size: 1em;
    line-height: 1.5;
    outline: none;
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    color: #363636;
    padding-left: 35px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 40px;
  }

  .colorPicker:hover {
    border-color: #b5b5b5;
  }
</style>

<div class="control has-icons-left has-icons-right {className}">
  <div
    class="select is-fullwidth showDelete is-multiple"
    class:showDelete={showInherit}>
    <input
      type="color"
      bind:value={selectedOrDefault}
      class="colorPicker"
      on:change={changeSelected} />
  </div>
  <span class="icon is-small is-left">
    <i class="fas fa-{icon}" />
  </span>
  {#if showInherit && selected !== undefined}
    <a
      class="icon is-small is-left"
      style="left: auto; right: 0; pointer-events:auto;"
      on:click={clear}>
      <i class="fas fa-times" />
    </a>
  {/if}
</div>
