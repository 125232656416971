<script>
  import { dm1 } from "../stores.js";
  import { onMount } from "svelte";
  import FilterDialogDirections from "./FilterDialogDirections.svelte";
  import FilterDialogPlatforms from "./FilterDialogPlatforms.svelte";

  let selectedTabIndex = 0;
</script>

<style>
  .field > label {
    width: 200px;
    line-height: 2;
  }
</style>

<div id="filterDialogTabs" class="tabs is-fullwidth">
  <ul>
    <li class:is-active={selectedTabIndex === 0}>
      <a  on:click={() => (selectedTabIndex = 0)}>
        <span class="icon is-small">
          <i class="fas fa-map-marker" aria-hidden="true" />
        </span>
        Platforms
      </a>
    </li>
    <li class:is-active={selectedTabIndex === 1}>
      <a on:click={() => (selectedTabIndex = 1)}>
        <span class="icon is-small">
          <i class="fas fa-exchange-alt" />
        </span>
        Directions
      </a>
    </li>
    <li class:is-active={selectedTabIndex === 2}>
      <a on:click={() => (selectedTabIndex = 2)}>
        <span class="icon is-small">
          <i class="fas fa-cog" />
        </span>
        Other
      </a>
    </li>
  </ul>

</div>

<div id="filterMenuContainer">
  {#if selectedTabIndex === 0}
    <FilterDialogPlatforms />
  {:else if selectedTabIndex === 1}
    <FilterDialogDirections />
  {:else if selectedTabIndex === 2}
    <div class="field is-grouped">
      <label class="label">Agencies</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Comma separated Agency Names"
          bind:value={$dm1.agencies} />
      </div>
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            value="include"
            bind:group={$dm1.agenciesMode} />
          Include
        </label>
        <label class="radio">
          <input
            type="radio"
            value="exclude"
            bind:group={$dm1.agenciesMode} />
          Exclude
        </label>
      </div>
    </div>

    <div class="field is-grouped">
      <label class="label">Lines</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Comma separated Line Names"
          bind:value={$dm1.lines} />
      </div>
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            value="include"
            bind:group={$dm1.linesMode} />
          Include
        </label>
        <label class="radio">
          <input
            type="radio"
            value="exclude"
            bind:group={$dm1.linesMode} />
          Exclude
        </label>
      </div>
    </div>

    <div class="field is-grouped ">
      <label class="label">Platform Texts</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Comma separated Platform Texts"
          bind:value={$dm1.platforms} />
      </div>
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            value="include"
            bind:group={$dm1.platformsMode} />
          Include
        </label>
        <label class="radio">
          <input
            type="radio"
            value="exclude"
            bind:group={$dm1.platformsMode} />
          Exclude
        </label>
      </div>
    </div>

    <div class="field is-grouped">
      <label class="label">Destination Texts</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Comma separated Destination Text"
          bind:value={$dm1.destinations} />
      </div>
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            value="include"
            bind:group={$dm1.destinationsMode} />
          Include
        </label>
        <label class="radio">
          <input
            type="radio"
            value="exclude"
            bind:group={$dm1.destinationsMode} />
          Exclude
        </label>
      </div>
    </div>
  {/if}
</div>
