<script>
  // core
  import { onMount, createEventDispatcher } from "svelte";

  // stores
  import { dm1 } from "../stores.js";
  import {
    columnConfigStore,
    currentColumnConfigStore
  } from "../store-column-config.js";

  // utils
  import { getColumnConfigPresetsForLayout } from "../util/column-config-utils.js";

  // input properties
  export let selectedColumnConfigPreset;
  export let isDefaultColumnConfigChanged = false;
  export let selectName = undefined;

  // dispatcher
  const dispatch = createEventDispatcher();

  let availableColumnConfigPresets = undefined;

  $: if (isDefaultColumnConfigChanged) {
    selectedColumnConfigPreset = "CUSTOM_LAYOUT";
  }
  $: if (selectedColumnConfigPreset !== "CUSTOM_LAYOUT") {
    dispatchChangeInSelectedColumnConfigPreset();
  }

  function dispatchChangeInSelectedColumnConfigPreset() {
    dispatch("changeInSelectedColumnConfigPreset", {
      payload: selectedColumnConfigPreset
    });
  }

  onMount(() => {
    availableColumnConfigPresets = getColumnConfigPresetsForLayout(
      $columnConfigStore,
      $dm1.uiSelectedLayout
    );
  });
</script>

<style>
  .column-config-select {
    margin: 0 0 2% 0;
  }
</style>

{#if availableColumnConfigPresets && selectedColumnConfigPreset !== undefined}
  <div class="column-config-select">
    {#if selectName}
    <label class="label">{selectName}:</label>
    {/if}
    <div class="select is-fullwidth">
      <select bind:value={selectedColumnConfigPreset}>
        {#each availableColumnConfigPresets as configPreset}
          <option value={configPreset.storeConfigName}>
            {configPreset.columnConfigName}
          </option>
        {/each}
        {#if isDefaultColumnConfigChanged}
          <option value="CUSTOM_LAYOUT">
            {$columnConfigStore[$currentColumnConfigStore.columnConfigId].uiColumnConfigPreset.columnConfigName}
            (Custom)
          </option>
        {/if}
      </select>
    </div>
  </div>
{/if}
