import "url-search-params-polyfill";
import { parse, stringify } from "qs";

function blankIfUndefined(v) {
  return v === undefined ? "" : v;
}

function formatColor(v) {
  return v === undefined ? "" : v.substring(1);
}

function encodeParameterValue(value) {
  let result = value;

  if (value === undefined) {
    result = "";
  } else if (typeof value === "string" || value instanceof String) {
    if (value.startsWith("#")) {
      result = value.substring(1);
    } else if (value.startsWith("http")) {
      // result = encodeURIComponent(value);
    }
  }
  return result;
}

function decodeUrlParameterValue(value) {
  let result = value;

  if (typeof value === "string" || value instanceof String) {
    if (value === "true") {
      result = true;
    } else if (value === "false") {
      result = false;
    } else if (value.length === 6 && /^[0-9A-F]{6}$/i.test(value)) {
      result = "#" + value;
    } else if (value.startsWith("http")) {
      // result = decodeURIComponent(value);
    } else if (value.length > 0 && /^[0-9]+$/i.test(value)) {
      result = parseInt(value);
    }
  }

  return result;
}

function encodeParameterKey(key) {
  const result = key;
  if (!(typeof key === "string" || key instanceof String)) {
    console.warn("Key is not string", key);
  }
  return result;
}

function decodeUrlParameterKey(key) {
  const result = key;
  if (!(typeof key === "string" || key instanceof String)) {
    console.warn("Key is not string", key);
  }
  return result;
}

function removeDefaultValuesFromConfig(config, defaultConfig) {
  const resultConfig = {};
  for (const customKey in config) {
    const defaultValue = defaultConfig[customKey];
    const customValue = config[customKey];
    if (defaultValue != customValue) {
      resultConfig[customKey] = customValue;
    }
  }

  return resultConfig;
}

export function serializeConfig(
  config,
  defaultConfig,
  slim,
  includeUiParameters
) {
  const slimConfig = slim
    ? removeDefaultValuesFromConfig(config, defaultConfig)
    : config;

  const hashParams = new URLSearchParams("");
  for (const key in slimConfig) {
    if (!includeUiParameters && key.startsWith("ui")) {
      continue;
    }

    // skip default fields
    if (key.startsWith("default")) {
      continue;
    }

    const value = slimConfig[key];

    const encodedValue = encodeParameterValue(value);
    const encodedKey = encodeParameterKey(key);

    // console.log("key: " + key + " = " + value + " (" + typeof value + ")");
    hashParams.set(encodedKey, encodedValue);
  }

  const serializedConfig = hashParams.toString();
  console.log("Serialized config: " + serializedConfig);
  return serializedConfig;
}

export function deserializeConfig(
  serializedConfig,
  defaultConfig,
  resultConfig
) {
  const hashParams = new URLSearchParams(serializedConfig);
  for (const hashParameterAndValue of hashParams.entries()) {
    const key = hashParameterAndValue[0];
    const value = hashParameterAndValue[1];

    const decodedKey = decodeUrlParameterKey(key);
    const decodedValue = decodeUrlParameterValue(value);

    if (!Object.prototype.hasOwnProperty.call(defaultConfig, decodedKey)) {
      console.warn(
        "Unknown key in serialized config: " +
          key +
          " " +
          decodedKey +
          " = " +
          decodedValue
      );
    }
    // console.log(key + ' / ' + decodedKey + " = '" + value + "' / '" + decodedValue + "'");
    resultConfig[decodedKey] = decodedValue;
  }
  console.log("Deserialized config: " + JSON.stringify(resultConfig));
}

export function parseQueryToConfig(query) {
  return parse(query);
}


const defaultStringifyOptions = {
  exclude: []
};

export function toQueryString(conf, options = defaultStringifyOptions) {

  const finalConf = {};
  for (const prop in conf) {
    if (options.exclude.length && options.exclude.includes(prop)) {
      continue;
    }
    finalConf[prop] = conf[prop];
  }

  return stringify(finalConf);
}
