<script>
  import { onMount } from "svelte";

  // enums
  import { ConfigurationTypeEnum } from "./manager/enum/configurationTypeEnum.js";

  // functions
  import { getScreenConfigurationsUidName } from "./manager/screen-configuration-api.js";

  // input properties
  export let userLoggedIn;
  export let loadedConfig;

  // internal properties
  let screenConfigurationList;
  let templateList;

  onMount(async () => {
    if (userLoggedIn) {
      await loadConfigurationsForNavbar();
    }
  });

  $: userLoggedIn && loadConfigurationsForNavbar();

  async function loadConfigurationsForNavbar() {
    // do not try to load configurations if user is not logged in
    if (!userLoggedIn) {
      return;
    }
    const screenConfigurationAndTemplateList = await getScreenConfigurationsUidName(
      undefined,
      10
    );
    screenConfigurationList = undefined;
    templateList = undefined;

    screenConfigurationList = screenConfigurationAndTemplateList.filter(
      item => {
        return item.type !== ConfigurationTypeEnum.TEMPLATE;
      }
    );
    templateList = screenConfigurationAndTemplateList.filter(item => {
      return item.type === ConfigurationTypeEnum.TEMPLATE;
    });

    const compareScreenConfigurations = function(a, b) {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    };
  }
</script>

{#if userLoggedIn}
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link">
      <span class="icon is-small">
        <i class="fas fa-folder" />
      </span>
      My Configurations
    </a>

    <div class="navbar-dropdown truncate-text" style="max-width: 280px;">
      <a
        href="manager.html#/screen-configuration?type=normal"
        class="navbar-item">
        Configuration Manager
      </a>
      <hr class="navbar-divider" />
      {#if screenConfigurationList && screenConfigurationList.length > 0}
        <div class="help">Recent Configurations:</div>
        {#each screenConfigurationList as screenConfiguration}
          <a
            href="index.html?c={screenConfiguration.uid}"
            class="navbar-item"
            class:has-text-weight-bold={loadedConfig && loadedConfig.uid === screenConfiguration.uid}>
            {screenConfiguration.name}
          </a>
        {/each}
      {/if}

      {#if templateList && templateList.length > 0}
        <hr class="navbar-divider" />
        <div class="help">Templates:</div>
        {#each templateList as screenConfiguration}
          <a
            href="index.html?c={screenConfiguration.uid}"
            class="navbar-item"
            class:has-text-weight-bold={loadedConfig && loadedConfig.uid === screenConfiguration.uid}>
            {screenConfiguration.name}
          </a>
        {/each}
      {/if}

    </div>
  </div>
{/if}
